import React from 'react'
import { LoginCheck } from './AuthCheck'

export default function ProtectedRoute(props) {
  LoginCheck();
  const { Component } = props;
  return (
    <Component />
  )
}
