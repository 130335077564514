import React, { useState } from "react";
import Accordion from "./about3Elements/Accordion";

export default function About3() {
  const [accordion, setAccordion] = useState(true);
  const [accordion2, setAccordion2] = useState(false);
  const [accordion3, setAccordion3] = useState(false);
  const [accordion4, setAccordion4] = useState(false);
  const [accordion5, setAccordion5] = useState(false);
  const [accordion6, setAccordion6] = useState(false);
  return (
    <section className="sm:py-16 py-8">
      <div className="lg:w-4/5 w-10/12 mx-auto sm:space-y-16 space-y-6">
        <h1 className="font-medium text-3xl text-center">
          Got any questions? Look here
        </h1>
        <div className="space-y-6">
          <Accordion
            extend={accordion}
            onOpen={() => {
              setAccordion(!accordion);
            }}
            accordionTitle="Does Allforcar also provide personal assistant?"
            accordionText="Yes Allforcar also provide personal assistant with business cars and other facilities. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. "
          />
          <Accordion
            extend={accordion2}
            onOpen={() => {
              setAccordion2(!accordion2);
            }}
            accordionTitle="What is Allforcar business account?"
            accordionText="Yes Allforcar also provide personal assistant with business cars and other facilities. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. "
          />
          <Accordion
            extend={accordion3}
            onOpen={() => {
              setAccordion3(!accordion3);
            }}
            accordionTitle="How to sign up as a driver?"
            accordionText="Yes Allforcar also provide personal assistant with business cars and other facilities. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. "
          />
          <Accordion
            extend={accordion4}
            onOpen={() => {
              setAccordion4(!accordion4);
            }}
            accordionTitle="Does Allforcar also provide personal assistant?"
            accordionText="Yes Allforcar also provide personal assistant with business cars and other facilities. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. "
          />
          <Accordion
            extend={accordion5}
            onOpen={() => {
              setAccordion5(!accordion5);
            }}
            accordionTitle="What is PA?"
            accordionText="Yes Allforcar also provide personal assistant with business cars and other facilities. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. "
          />
          <Accordion
            extend={accordion6}
            onOpen={() => {
              setAccordion6(!accordion6);
            }}
            accordionTitle="How to sign up as a personal assistant?"
            accordionText="Yes Allforcar also provide personal assistant with business cars and other facilities. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. "
          />
        </div>
      </div>
    </section>
  );
}
