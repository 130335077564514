import React from "react";

export default function About2() {
  return (
    <section className="sm:py-16 py-8">
      <div className="lg:w-4/5 w-10/12 mx-auto relative z-10 space-y-16">
        <div className="grid lg:grid-cols-2 gap-y-12">
          <div className="lg:w-4/5 flex flex-col justify-center lg:gap-y-4 gap-y-1 lg:order-1 order-1 lg:text-start text-center">
            <h1 className="font-semibold text-3xl">Freedom of movement</h1>
            <p className="font-normal text-sm text-black text-opacity-60">
              We provide regular and specialized vehicles: wheelchair accessible
              disabled vehicles, minibuses, limousines, coaches or and other
              vehicles custom made for the comfort of larger people or groups.
              We understand the industry well from our many years of experience
              in the care industry. We have over 21 years developed specialist
              services to meet the requirements of people with special needs.{" "}
              <br /> We also provide Baby seats, Booster seats, Harnesses,
              Hoists, Wheel Chairs, Mobility Scooters, Transfer boards, Standing
              Aids and other disability support equipment at extra charges.
            </p>
          </div>
          <div className="flex justify-center items-center lg:order-2 order-2">
            <div className="w-fit relative">
              <img
                src="/images/about/sec2-img1.webp"
                alt="collection"
                className="w-[480px]"
              />
              <div className="w-fit bg-white sm:py-3 py-2 sm:px-6 px-4 rounded-lg shadow-lg absolute top-6 sm:-left-10 -left-4">
                <img
                  src="/images/about/popper.webp"
                  alt="popper"
                  className="sm:w-6 w-4"
                />
              </div>
              <div className="w-fit bg-white sm:py-3 py-2 sm:px-6 px-4 rounded-lg shadow-lg absolute bottom-12 left-[40%] -translate-x-[40%]">
                <img
                  src="/images/about/heart.webp"
                  alt="heart"
                  className="sm:w-6 w-4"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center lg:order-3 order-4">
            <div className="w-fit relative">
              <img
                src="/images/about/sec2-img2.webp"
                alt="collection"
                className="w-80"
              />
              <div className="w-fit bg-white sm:py-3 py-2 sm:px-6 px-4 rounded-lg shadow-lg absolute bottom-4 sm:-right-10 -right-6">
                <img
                  src="/images/about/emoji.webp"
                  alt="emoji"
                  className="sm:w-6 w-4"
                />
              </div>
            </div>
          </div>
          <div className="lg:w-4/5 flex flex-col justify-center lg:gap-y-4 gap-y-1 lg:order-4 order-3 lg:text-start text-center">
            <h1 className="font-semibold text-3xl">Our cars</h1>
            <p className="font-normal text-sm text-black text-opacity-60">
              All our vehicles are state of the art, green (ULEZ compliant),
              clean and are appropriately insured to cover both drivers and
              passengers, and equipment. <br />
              <br /> Our vehicles are TFL/PH (PCO) registered, except where
              statutorily exempt, and all drivers are equally licensed by
              Transport for London, except where an exempt vehicle applies.
              Allfor Cars also possess Transport for London Private Hire
              Operator Licence.
            </p>
          </div>
        </div>
        <div className="bg-themeBlue rounded-xl pt-5 pb-8 space-y-8">
          <h1 className="font-medium sm:text-3xl text-2xl text-white text-center">
            Allforcar in numbers
          </h1>
          <div className="grid sm:grid-cols-4 grid-cols-2 gap-y-5">
            <div className="flex justify-center items-center border-r border-white text-center">
              <div>
                <h3 className="font-medium text-2xl text-white">1500+</h3>
                <p className="font-light text-xs text-white">Employees</p>
              </div>
            </div>
            <div className="flex justify-center items-center sm:border-r sm:border-white text-center">
              <div>
                <h3 className="font-medium text-2xl text-white">24400+</h3>
                <p className="font-light text-xs text-white">Worldwide users</p>
              </div>
            </div>
            <div className="flex justify-center items-center border-r border-white text-center">
              <div>
                <h3 className="font-medium text-2xl text-white">45+</h3>
                <p className="font-light text-xs text-white">Cities in UK</p>
              </div>
            </div>
            <div className="flex justify-center items-center text-center">
              <div>
                <h3 className="font-medium text-2xl text-white">250+</h3>
                <p className="font-light text-xs text-white">
                  Registered drivers
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
