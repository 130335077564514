import React from "react";
import Section2Card from "./section2Elements/Section2Card";

export default function Section2() {
  return (
    <section className="bg-themeBlue2 sm:py-20 py-8">
      <div className="grid lg:grid-cols-3 sm:grid-cols-2 xl:w-3/4 lg:w-4/5 w-10/12 mx-auto xl:gap-x-12 gap-x-8 gap-y-8">
        <Section2Card
          topBorder="border-t-themeBlue"
          icon="1"
          title="Safe and secure"
          text="Safety first, second, third, fourth… Travel confidently with best in class safety and hygiene. Trained, insured and vetted drivers."
        />
        <Section2Card
          topBorder="border-t-themeOrange"
          icon="2"
          title="Fast & reliable"
          text="Safety first, second, third, fourth… Travel confidently with best in class safety and hygiene. Trained, insured and vetted drivers."
        />
        <Section2Card
          topBorder="border-t-themeGreen"
          icon="3"
          title="Easy to use"
          text="Safety first, second, third, fourth… Travel confidently with best in class safety and hygiene. Trained, insured and vetted drivers."
        />
      </div>
    </section>
  );
}
