import React, { useMemo } from "react";
import { GrPrint } from "react-icons/gr";
import { HiArrowLeft } from "react-icons/hi";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

export default function TripDetails(props) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCYC3-gTg2XJFIeo9fura6PoNuQzzPeBlc",
  });
  const center = useMemo(() => ({ lat: 31.5204, lng: 74.3587 }), []);
  const containerStyle = {
    width: "100%",
    height: "160px",
  };
  return (
    <div className="px-5 py-4 border border-black border-opacity-40 rounded-lg space-y-5">
      <div className="flex items-center justify-between">
        <button onClick={props.onClick}>
          <HiArrowLeft size={24} />
        </button>
        <div className="flex items-center gap-x-4">
          <button className="font-normal text-sm text-black text-opacity-60">
            Report a problem?
          </button>
          <button className="font-normal text-sm text-black text-opacity-80 border border-black rounded px-3 py-1.5 flex items-center gap-x-2">
            <GrPrint size={24} />
            <span>Print</span>
          </button>
        </div>
      </div>
      <h4 className="font-medium text-base">Your trip</h4>
      <div className="flex sm:flex-row flex-col justify-between items-center gap-y-2">
        <p className="font-light text-sm">
          Thursday, Feb 04 2022, 1:37 AM with Ahmad Salman
        </p>
        <button className="font-normal text-sm text-themeGreen2">
          Completed
        </button>
      </div>
      <div>
        {isLoaded && (
          <GoogleMap
            zoom={10}
            center={center}
            mapContainerStyle={containerStyle}
          >
            <MarkerF position={center} />
          </GoogleMap>
        )}
      </div>
      <div className="grid grid-cols-12">
        <div className="sm:col-span-1 col-span-2">
          <div className="flex flex-col items-center justify-center py-1 gap-y-1">
            <div className="w-6 h-6 border-[6px] border-themeBlue rounded-fullest"></div>
            <img
              src="/images/booking/line.webp"
              alt="line"
              className="w-1.5 h-20"
            />
            <div className="w-6 h-6 border-[6px] border-themeBlue"></div>
          </div>
        </div>
        <div className="sm:col-span-6 col-span-10 flex flex-col justify-between font-normal text-xs">
          <p>
            16M Abdul Haque Rd, Trade Centre Commercial Area Phase 2 Johar Town,
            Lahore, Punjab 54000
          </p>
          <p>
            P Block, DHA, Main, Ghazi Rd, near Rehman Gardens, Rehman Gardens,
            Lahore, Punjab
          </p>
        </div>
      </div>
      <hr className="h-0.5 bg-black bg-opacity-20 border-none" />
      <div className="flex sm:flex-row flex-col items-center justify-between">
        <p className="font-normal text-base">
          Rate your experience with driver
        </p>
        <div className="flex items-center gap-x-1">
          <AiOutlineStar size={32} className="text-themeBlue" />
          <AiOutlineStar size={32} className="text-themeBlue" />
          <AiOutlineStar size={32} className="text-themeBlue" />
          <AiOutlineStar size={32} className="text-themeBlue" />
          <AiOutlineStar size={32} className="text-themeBlue" />
        </div>
      </div>
      <div className="flex sm:flex-row flex-col items-center justify-between">
        <p className="font-normal text-base">
          Rate your experience with passenger assistant
        </p>
        <div className="flex items-center gap-x-1">
          <AiFillStar size={32} className="text-themeBlue" />
          <AiFillStar size={32} className="text-themeBlue" />
          <AiFillStar size={32} className="text-themeBlue" />
          <AiFillStar size={32} className="text-themeBlue" />
          <AiOutlineStar size={32} className="text-themeBlue" />
        </div>
      </div>
      <hr className="h-0.5 bg-black bg-opacity-20 border-none" />
      <div className="space-y-2">
        <h5 className="font-medium text-base">Service charges</h5>
        <div className="flex items-center justify-between font-normal text-sm">
          <ul className="flex flex-col items-start gap-y-1">
            <li>Base fare</li>
            <li>Service fee</li>
            <li>Waiting time</li>
            <li>PA Charges</li>
            <li>Total</li>
          </ul>
          <ul className="flex flex-col items-end gap-y-1">
            <li>$ 60.60</li>
            <li>$ 40.60</li>
            <li>$ 0.00</li>
            <li>$ 36.00</li>
            <li>$ 136.45</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
