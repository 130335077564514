// @ts-nocheck
import React, { useState } from "react";
import { BsPlus } from "react-icons/bs";
import { HiTrash } from "react-icons/hi";
import Card from "react-credit-card-component";
import "react-credit-card-component/dist/styles-compiled.css";
import GetAPI from "../../../api/GetAPI";
import { PostAPI } from "../../../api/PostAPI";
import {
  error_toaster,
  info_toaster,
  success_toaster,
} from "../../../toaster/Toaster";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useMediaQuery,
} from "@chakra-ui/react";

export default function MyWallet() {
  const { data, reFetch } = GetAPI("card_list");
  const getProfile = GetAPI("user/profile");
  const [add, setAdd] = useState(false);
  const [card, setCard] = useState({
    cardNo: "",
    month: "",
    year: "",
    cvc: "",
  });
  const paymentFunc = async (e) => {
    e.preventDefault();
    let res = await PostAPI("add_card", {
      user_id: getProfile?.data?.data?.id,
      card_number: card.cardNo,
      exp_month: card.month,
      exp_year: card.year,
      cvc: card.cvc,
      card_save: true,
    });
    if (res?.data?.status) {
      success_toaster(res?.data?.message);
      setAdd(false);
      setCard({ cardNo: "", month: "", year: "", cvc: "" });
      reFetch();
    } else {
      error_toaster(res?.data?.message);
    }
  };
  const onChange = (e) => {
    setCard({ ...card, [e.target.name]: e.target.value });
  };
  const removeCard = async (pm_id) => {
    let res = await PostAPI("removeCard", {
      user_id: getProfile?.data?.data?.id,
      pm_id: pm_id,
    });
    if (res?.data?.status) {
      info_toaster(res?.data?.message);
      reFetch();
    } else {
      error_toaster(res?.data?.message);
    }
  };
  const [xs] = useMediaQuery("(min-width: 480px)");
  const inputStyle =
    "block w-full rounded px-5 py-2 bg-transparent border border-black border-opacity-20 font-normal text-sm focus:outline-none";
  return (
    <>
      <Modal
        onClose={() => {
          setAdd(false);
          setCard({ cardNo: "", month: "", year: "", cvc: "" });
        }}
        isOpen={add}
        isCentered
        size={xs ? "md" : "xs"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <ModalCloseButton />
            <div className="sm:px-6 px-2 py-8">
              <h3 className="font-semibold text-lg">Add Payment Method</h3>
              <hr className="h-0.5 bg-black bg-opacity-20 border-none my-2" />
              <div className="mt-4 grid grid-cols-2 gap-3">
                <div className="space-y-1 col-span-2">
                  <input
                    value={card.cardNo}
                    onChange={onChange}
                    type="number"
                    name="cardNo"
                    id="cardNo"
                    placeholder="Card Number"
                    className={inputStyle}
                  />
                </div>
                <div className="space-y-1">
                  <input
                    value={card.month}
                    onChange={onChange}
                    type="number"
                    name="month"
                    id="month"
                    placeholder="Expiry Month"
                    className={inputStyle}
                  />
                </div>
                <div className="space-y-1">
                  <input
                    value={card.year}
                    onChange={onChange}
                    type="number"
                    name="year"
                    id="year"
                    placeholder="Expiry Year"
                    className={inputStyle}
                  />
                </div>
                <div className="space-y-1">
                  <input
                    value={card.cvc}
                    onChange={onChange}
                    type="number"
                    name="cvc"
                    id="cvc"
                    placeholder="CVC"
                    className={inputStyle}
                  />
                </div>
                <div className="flex justify-end col-span-2">
                  <button
                    onClick={paymentFunc}
                    className="bg-themeBlue py-2 px-5 rounded font-normal text-sm text-white border border-themeBlue hover:bg-transparent hover:text-themeBlue"
                  >
                    Save & update
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <section className="lg:w-1/2 md:w-3/4 sm:10/12 w-11/12 mx-auto my-12 space-y-8">
        <div className="bg-themeGray2 rounded-xl px-8 pt-10 pb-16">
          <h5 className="font-normal text-sm text-black text-opacity-60">
            Allforcar cash
          </h5>
          <h1 className="font-semibold text-3xl">PKR 0.00</h1>
        </div>
        <div className="flex sm:flex-row flex-col justify-between items-center gap-y-2">
          <h3 className="font-medium text-xl">Payment methods</h3>
          <button
            onClick={() => setAdd(true)}
            className="bg-transaprent text-themeBlue fontnormal text-base flex items-center gap-x-1 border border-themeBlue py-2 px-6 rounded hover:bg-themeBlue hover:text-white"
          >
            <BsPlus size={24} />
            <span>Add new method</span>
          </button>
        </div>
        <div className="grid md:grid-cols-2 gap-x-8 gap-y-3">
          {data?.other_cards?.map((card, key) => (
            <div className="[&>div]:m-0 flex gap-x-5 items-center">
              <Card
                cvc="300"
                expiry="0000"
                focused=""
                name={localStorage.getItem("userName")}
                number={`000000000000${card?.last4digit}`}
              />
              <button onClick={() => removeCard(card?.pm_id)}>
                <HiTrash
                  size={32}
                  className="text-black text-opacity-30 hover:text-themeBlue hover:text-opacity-100"
                />
              </button>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
