import axios from "axios"
import { BASE_URL } from "./API"

export const PostAPI = async (url, postData) => {
    let config = {
        headers: {
            accessToken: localStorage.getItem("accessToken"),
            Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
            "Content-Type": "multipart/form-data",
        },
    };
    try {
        const res = await axios.post(BASE_URL + url, postData, config);
        return res;
    } catch (err) { }
}

export const PostAPI2 = async (url, postData) => {
    let config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    };
    try {
        const res = await axios.post(BASE_URL + url, postData, config);
        return res;
    } catch (err) { }
}