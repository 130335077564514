import React from "react";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import Services1 from "./services1/Services1";
import Services2 from "./services2/Services2";
import About4 from "../about/about4/About4";

export default function Services() {
  return (
    <>
      <Header />
      <Services1 />
      <Services2 />
      <About4 />
      <Footer />
    </>
  );
}
