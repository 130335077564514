import React from "react";
import { FiArrowUpRight } from "react-icons/fi";

export default function Services1() {
  return (
    <section className="w-10/12 mx-auto sm:py-16 py-12 flex flex-col justify-center items-center sm:gap-y-8 gap-y-5 relative">
      <h1 className="font-semibold sm:text-3xl text-2xl text-center">
        <span className="text-themeBlue">London’s dedicated</span> minicar
        service for people <br className="md:block hidden" /> with disabilities/
        special needs.
      </h1>
      <div className="flex sm:flex-row flex-col items-center gap-x-4 gap-y-2">
        <div>
          <button className="bg-themeBlue text-white font-normal text-xm py-2 px-5 sm:w-auto w-52 uppercase rounded border border-themeBlue hover:bg-transparent hover:text-themeBlue">
            Ride with us
          </button>
        </div>
        <div>
          <button className="bg-transparent text-themeBlue font-normal text-xm py-2 px-5 sm:w-auto w-52 uppercase flex items-center gap-x-2 rounded border border-themeBlue hover:bg-themeBlue hover:text-white">
            <span>Become a Driver</span>
            <FiArrowUpRight size={16} />
          </button>
        </div>
      </div>
      <div className="w-fit bg-white px-4 py-2 rounded-lg shadow-around absolute left-[10%] xl:top-1/4 top-0 sm:block hidden">
        <img src="/images/about/emoji.webp" alt="emoji" className="w-6" />
      </div>
      <div className="w-fit bg-white px-4 py-2 rounded-lg shadow-around absolute xl:right-[10%] -right-5 top-1/4 lg:block hidden">
        <img src="/images/services/car.webp" alt="car" className="w-6" />
      </div>
      <div className="w-fit bg-white px-4 py-2 rounded-lg shadow-around absolute xl:right-[20%] right-2 lg:top-2/4 top-[80%] sm:block hidden">
        <img src="/images/about/heart.webp" alt="heart" className="w-6" />
      </div>
    </section>
  );
}
