import React, { useState } from "react";
import Header from "../layout/Header";
import { TbRoute } from "react-icons/tb";
import TabHead from "./dashboardElements/TabHead";
import { RiWallet2Line } from "react-icons/ri";
import { AiOutlineUser } from "react-icons/ai";
import MyTrips from "./myTrips/MyTrips";
import MyWallet from "./myWallet/MyWallet";
import ProfileSettings from "./profileSettings/ProfileSettings";

export default function Dashboard() {
  const [activeTab, setActiveTab] = useState("trips");
  return (
    <>
      <Header />
      <section className="my-2">
        <div className="bg-themeBlue3 px-5 py-2 flex justify-center items-center sm:gap-x-6 gap-x-1">
          <TabHead
            onClick={() => {
              setActiveTab("trips");
            }}
            Icon={TbRoute}
            tabTitle="My trips"
            active={activeTab === "trips" ? true : false}
          />
          <TabHead
            onClick={() => {
              setActiveTab("wallet");
            }}
            Icon={RiWallet2Line}
            tabTitle="My Wallet"
            active={activeTab === "wallet" ? true : false}
          />
          <TabHead
            onClick={() => {
              setActiveTab("profile");
            }}
            Icon={AiOutlineUser}
            tabTitle="Profile Settings"
            active={activeTab === "profile" ? true : false}
          />
        </div>
        {activeTab === "trips" ? (
          <MyTrips />
        ) : activeTab === "wallet" ? (
          <MyWallet />
        ) : (
          <ProfileSettings />
        )}
      </section>
    </>
  );
}
