import React from "react";
import { GiPerson } from "react-icons/gi";
import { TbFaceMask } from "react-icons/tb";
import { GrSchedule } from "react-icons/gr";
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function Section4() {
  return (
    <section className="sm:py-20 py-8">
      <div className="grid md:grid-cols-2 gap-x-12 gap-y-12 xl:w-3/5 lg:w-4/6 w-4/5 mx-auto">
        <div className="flex md:justify-end justify-center items-center">
          <div className="flex flex-col sm:gap-y-7 gap-y-5 md:items-start items-center md:text-start text-center">
            <h6 className="font-normal text-sm text-themeBlue uppercase">
              Ride with Allforcar
            </h6>
            <h1 className="font-semibold sm:text-3xl text-2xl">
              Ready, set, go <br /> in just a few quick taps
            </h1>
            <h1 className="font-normal text-base text-black text-opacity-60">
              No matter your destination, we’ll get you where you need to go
            </h1>
            <div className="sm:space-y-5 space-y-3">
              <p className="font-medium sm:text-base text-sm flex items-center gap-x-3">
                <GiPerson size={24} />
                <span>Get a reliable ride in minutes</span>
              </p>
              <p className="font-medium sm:text-base text-sm flex items-center gap-x-3">
                <TbFaceMask size={24} />
                <span>Know that your driver is wearing a mask</span>
              </p>
              <p className="font-medium sm:text-base text-sm flex items-center gap-x-3">
                <GrSchedule size={24} />
                <span>Schedule your ride in advance</span>
              </p>
            </div>
            <div className="flex sm:flex-row flex-col items-center gap-x-4 gap-y-3">
              <Link
                to={"/booking"}
                className="bg-themeBlue font-normal text-base text-white py-2 px-4 rounded border border-themeBlue hover:bg-transparent hover:text-themeBlue"
              >
                Get a ride
              </Link>
              <p className="font-normal text-base text-themeBlue flex items-center gap-x-2">
                <span>Learn more about riding with us</span>
                <BsArrowRightShort size={24} />
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div>
            <img src="/images/mobile.webp" alt="mobile" className="w-44" />
          </div>
        </div>
      </div>
    </section>
  );
}
