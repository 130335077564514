import React from "react";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import Driver1 from "./driver1/Driver1";

export default function Driver() {
  return (
    <>
      <Header />
      <Driver1 />
      <Footer />
    </>
  );
}
