import React, { useState } from "react";
import { AiFillCar } from "react-icons/ai";
import { BsFillCreditCard2BackFill } from "react-icons/bs";
import { FaWifi } from "react-icons/fa";
import { FiMonitor } from "react-icons/fi";
import { RiTimerFlashLine } from "react-icons/ri";
import PhoneInput from "react-phone-input-2";
import { Link, useNavigate } from "react-router-dom";
import { PostAPI } from "../../api/PostAPI";
import { MiniLoader } from "../../loader/Loader";
import {
  error_toaster,
  info_toaster,
  success_toaster,
} from "../../toaster/Toaster";

export default function Signup() {
  const [phone, setPhone] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [edit, setEdit] = useState({
    fName: "",
    lName: "",
    email: "",
  });
  const signupFunc = async (e) => {
    e.preventDefault();
    setLoader(true);
    let res = await PostAPI("user/signup", {
      first_name: edit.fName,
      last_name: edit.lName,
      phone: "+" + phone,
      email: edit.email,
      device_token: "test",
    });
    if (edit.fName === "") {
      setLoader(false);
      info_toaster("Please fill First Name");
    } else if (edit.lName === "") {
      setLoader(false);
      info_toaster("Please fill Last Name");
    } else if (edit.email === "") {
      setLoader(false);
      info_toaster("Please fill Email");
    } else if (phone === "") {
      setLoader(false);
      info_toaster("Please fill Phone");
    } else if (res?.data.status) {
      setLoader(false);
      success_toaster("Account Created Successfully!");
      navigate("/sign_in");
    } else {
      setLoader(false);
      error_toaster(res?.data.message);
    }
  };
  const onChange = (e) => {
    setEdit({ ...edit, [e.target.name]: e.target.value });
  };
  const labelStyle = "font-normal text-sm";
  const inputStyle =
    "bg-transparent block w-full border border-black border-opacity-20 font-normal text-base rounded py-3 px-5 focus:outline-none placeholder:font-light";
  return (
    <section className="sm:py-16 py-10 flex flex-col sm:gap-y-12 gap-y-6 justify-center items-center relative after:content-[''] after:absolute after:bg-themeBlue after:w-full after:h-1/2 after:top-0 after:left-0">
      <h1 className="font-medium sm:text-4xl text-3xl text-white text-center relative z-20 w-11/12 mx-auto">
        We need a few details to get you started
      </h1>
      <div className="xl:w-3/5 sm:w-3/4 w-11/12 mx-auto bg-white rounded-md shadow-around relative z-20">
        <div className="grid grid-cols-12 px-4">
          <div className="lg:col-span-8 sm:col-span-6 col-span-12 cols sm:py-12 py-6 relative">
            {loader ? (
              <MiniLoader />
            ) : (
              <form
                method="post"
                className="lg:w-11/12 lg:mx-auto lg:pr-0 sm:pr-4 flex flex-col gap-y-7"
              >
                <h5 className="font-medium text-2xl text-center">
                  Create account
                </h5>
                <div className="grid lg:grid-cols-2 gap-x-3 gap-y-4">
                  <div className="space-y-1">
                    <div>
                      <label htmlFor="fName" className={labelStyle}>
                        First Name
                      </label>
                    </div>
                    <input
                      onChange={onChange}
                      value={edit.fName}
                      type="text"
                      name="fName"
                      id="fName"
                      placeholder="Enter first name"
                      className={inputStyle}
                    />
                  </div>
                  <div className="space-y-1">
                    <div>
                      <label htmlFor="lName" className={labelStyle}>
                        Last name
                      </label>
                    </div>
                    <input
                      onChange={onChange}
                      value={edit.lName}
                      type="text"
                      name="lName"
                      id="lName"
                      placeholder="Enter last name"
                      className={inputStyle}
                    />
                  </div>
                  <div className="space-y-1 lg:col-span-2">
                    <div>
                      <label htmlFor="email" className={labelStyle}>
                        Email
                      </label>
                    </div>
                    <input
                      onChange={onChange}
                      value={edit.email}
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter email address"
                      className={inputStyle}
                    />
                  </div>
                  <div className="space-y-1 lg:col-span-2">
                    <div>
                      <label htmlFor="phone" className={labelStyle}>
                        Phone
                      </label>
                    </div>
                    <PhoneInput
                      inputStyle={{
                        display: "block",
                        width: "100%",
                        paddingTop: "24px",
                        paddingBottom: "24px",
                      }}
                      inputProps={{ id: "phone", name: "phone" }}
                      country="pk"
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                      onEnterKeyPress={signupFunc}
                    />
                  </div>
                  {/* <div className="space-y-1 lg:col-span-2">
                  <div>
                    <label htmlFor="password" className={labelStyle}>
                      Password
                    </label>
                  </div>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Create password"
                    className={inputStyle}
                  />
                </div>
                <div className="space-y-1 lg:col-span-2">
                  <div>
                    <label htmlFor="c_password" className={labelStyle}>
                      Confirm Password
                    </label>
                  </div>
                  <input
                    type="password"
                    name="c_password"
                    id="c_password"
                    placeholder="Confirm password"
                    className={inputStyle}
                  />
                </div>
                <div className="flex items-center gap-x-3">
                  <input
                    type="checkbox"
                    name="agree"
                    id="agree"
                    className="outline outline-1 outline-black border-none rounded-none"
                  />
                  <label
                    htmlFor="agree"
                    className="font-normal lg:text-sm text-xs text-black text-opacity-60"
                  >
                    I agree to{" "}
                    <span className="text-black underline">
                      Terms & Conditions
                    </span>
                  </label>
                </div> */}
                </div>
                <div className="flex flex-col gap-y-3 sm:w-fit">
                  <button
                    type="submit"
                    onClick={signupFunc}
                    className="bg-themeBlue text-white font-normal text-sm py-4 px-12 rounded-md border border-themeBlue hover:bg-transparent hover:text-themeBlue"
                  >
                    Create Account
                  </button>
                  <Link
                    to={"/sign_in"}
                    className="font-normal text-sm text-center sm:hidden block"
                  >
                    Already have an account?
                  </Link>
                </div>
              </form>
            )}
          </div>
          <div className="lg:col-span-4 sm:col-span-6 sm:block hidden relative">
            <div className="bg-themeSkin px-5 py-12 space-y-8 h-[104%] -mt-[5%] flex flex-col rounded-lg">
              <div className="space-y-4">
                <h1 className="font-medium text-sm uppercase text-center">
                  Premium rides
                </h1>
                <p className="font-normal text-xs text-center">
                  There are tons of advantages of joining Allforcar today.
                  Including...
                </p>
              </div>
              <div className="space-y-3 w-10/12 mx-auto">
                <div className="flex flex-col items-center text-center gap-y-3">
                  <RiTimerFlashLine size={24} />
                  <span className="font-normal text-xs">
                    10 min pick ups in central London
                  </span>
                </div>
                <hr className="bg-black h-[1px] border-none" />
                <div className="flex flex-col items-center text-center gap-y-3">
                  <FiMonitor size={24} />
                  <span className="font-normal text-xs">
                    App and web booking discounts
                  </span>
                </div>
                <hr className="bg-black h-[1px] border-none" />
                <div className="flex flex-col items-center text-center gap-y-3">
                  <FaWifi size={24} />
                  <span className="font-normal text-xs">
                    Free 4g Wi-fi and phone chargers in all cars
                  </span>
                </div>
                <hr className="bg-black h-[1px] border-none" />
                <div className="flex flex-col items-center text-center gap-y-3">
                  <AiFillCar size={24} />
                  <span className="font-normal text-xs">Premium cars</span>
                </div>
                <hr className="bg-black h-[1px] border-none" />
                <div className="flex flex-col items-center text-center gap-y-3">
                  <BsFillCreditCard2BackFill size={24} />
                  <span className="font-normal text-xs">
                    Direct debit saving
                  </span>
                </div>
                <hr className="bg-black h-[1px] border-none" />
              </div>
              <div className="flex justify-center">
                <Link
                  to={"/sign_in"}
                  className="bg-white font-normal text-sm py-4 px-3 w-full text-center rounded-md border border-white"
                >
                  Already have an account?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
