// @ts-nocheck
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { PostAPI2 } from "../../../api/PostAPI";
import {
  error_toaster,
  info_toaster,
  success_toaster,
} from "../../../toaster/Toaster";

export default function PA1() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [homeTel, setHomeTel] = useState("");
  const [phone, setPhone] = useState("");
  const [paForm, setPaForm] = useState({
    fName: "",
    lName: "",
    password: "",
    address: "",
    postCode: "",
    gender: "",
    dob: "",
    insurance: "",
    dbs: "",
    validFrom: "",
    validTo: "",
    paStart: "",
    paEnd: "",
    paAvail: "",
    issue: "",
    expiry: "",
    lisence: "",
    email: "",
    dbsBack: "",
    dbsFront: "",
    profile: "",
  });
  const onChange = (e) => {
    setPaForm({ ...paForm, [e.target.name]: e.target.value });
  };
  const imgHandle = (e) => {
    setPaForm({ ...paForm, [e.target.name]: e.target.files[0] });
  };
  const checkOne = (e) => {
    e.preventDefault();
    if (paForm.fName === "") {
      info_toaster("Please fill First Name field");
    } else if (paForm.lName === "") {
      info_toaster("Please fill Last Name field");
    } else if (paForm.email === "") {
      info_toaster("Please enter your Email");
    } else if (paForm.password === "") {
      info_toaster("Please fill Password field");
    } else if (homeTel < 4) {
      info_toaster("Please fill Home Telephone Number field");
    } else if (phone < 4) {
      info_toaster("Please fill Phone Number field");
    } else if (paForm.address === "") {
      info_toaster("Please enter your Address");
    } else if (paForm.postCode === "") {
      info_toaster("Please enter your Postcode");
    } else if (paForm.gender === "") {
      info_toaster("Please select your Gender");
    } else if (paForm.dob === "") {
      info_toaster("Please enter Date of Birth");
    } else {
      setTimeline("2");
    }
  };
  const checkTwo = (e) => {
    e.preventDefault();
    if (paForm.insurance === "") {
      info_toaster("Please enter National Insurance Number");
    } else if (paForm.dbs === "") {
      info_toaster("Please enter DBS Number");
    } else if (paForm.validFrom === "") {
      info_toaster("Please enter DBS Issue Date");
    } else if (paForm.validTo === "") {
      info_toaster("Please enter DBS Expiry Date");
    } else if (paForm.paStart === "") {
      info_toaster("Please enter PA Training Completion Date");
    } else if (paForm.paEnd === "") {
      info_toaster("Please enter PA Training End Date");
    } else if (paForm.paAvail === "") {
      info_toaster("Please enter PA Training Availibility Date");
    } else if (paForm.lisence === "") {
      info_toaster("Please enter Lisence Number");
    } else if (paForm.issue === "") {
      info_toaster("Please enter ID Issue Date");
    } else if (paForm.expiry === "") {
      info_toaster("Please enter ID Expiry Date");
    } else {
      setTimeline("3");
    }
  };
  const paFunc = async (e) => {
    e.preventDefault();
    setLoader(true);
    let res = await PostAPI2("pa/signup", {
      first_name: paForm.fName,
      last_name: paForm.lName,
      password: paForm.password,
      address: paForm.address,
      postcode: paForm.postCode,
      gender: paForm.gender,
      home_telephone: "+" + homeTel,
      phone: "+" + phone,
      date_of_birth: paForm.dob,
      national_insurance_number: paForm.insurance,
      DBS_number: paForm.dbs,
      dbs_valid_from: paForm.validFrom,
      dbs_valid_to: paForm.validTo,
      pa_training_complete_at: paForm.paStart,
      pa_training_expire_at: paForm.paEnd,
      pa_training_availability_at: paForm.paAvail,
      id_issue_date: paForm.issue,
      id_expiry_date: paForm.expiry,
      license_no: paForm.lisence,
      email: paForm.email,
      dbs_back_image: paForm.dbsBack,
      dbs_front_image: paForm.dbsFront,
      driver_image: paForm.profile,
      device_token: "test",
    });
    if (paForm.dbsBack === "") {
      info_toaster("Please enter DBS Back Image");
    } else if (paForm.dbsFront === "") {
      info_toaster("Please enter DBS Front Image");
    } else if (paForm.profile === "") {
      info_toaster("Please enter Profile Image");
    } else if (res?.data?.status) {
      setLoader(false);
      navigate("/");
      success_toaster(res?.data?.message);
    } else {
      setLoader(false);
      error_toaster(res?.data?.message);
    }
  };
  const [timeline, setTimeline] = useState("1");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [timeline]);
  const labelStyle = "font-normal md:text-base text-sm";
  const inputStyle =
    "bg-white block w-full px-4 py-3 border border-black border-opacity-20 rounded font-normal text-base focus:outline-none placeholder:text-opacity-40";
  const dropdownStyle =
    "bg-white block w-full px-4 py-4 border border-black border-opacity-20 rounded font-normal text-sm cursor-pointer focus:outline-none placeholder:text-opacity-40";
  return (
    <section className="pt-8 pb-16">
      <form className="lg:w-4/5 sm:w-10/12 w-11/12 mx-auto bg-themeGray2 rounded-xl py-12 px-8 grid sm:grid-cols-2 gap-x-5 gap-y-4">
        <h1 className="sm:col-span-2 text-4xl font-bold text-center text-black text-opacity-80">
          Signup for Personal Assistance
        </h1>
        <div className="col-span-2 flex items-center text-lg my-4">
          <div
            className={`
              ${
                timeline === "1" || timeline === "2" || timeline === "3"
                  ? "bg-themeBlue text-white"
                  : "bg-black bg-opacity-20"
              }
                min-w-[40px] min-h-[40px] rounded-fullest flex justify-center items-center`}
          >
            1
          </div>
          <div
            className={`${
              timeline === "2" || timeline === "3"
                ? "border-y-themeBlue"
                : "border-y-black border-opacity-20"
            } w-full h-0 border-y mx-4`}
          ></div>
          <div
            className={`
              ${
                timeline === "2" || timeline === "3"
                  ? "bg-themeBlue text-white"
                  : "bg-black bg-opacity-20"
              }
                min-w-[40px] min-h-[40px] rounded-fullest flex justify-center items-center`}
          >
            2
          </div>
          <div
            className={`${
              timeline === "3"
                ? "border-y-themeBlue"
                : "border-y-black border-opacity-20"
            } w-full h-0 border-y mx-4`}
          ></div>
          <div
            className={`
              ${
                timeline === "3"
                  ? "bg-themeBlue text-white"
                  : "bg-black bg-opacity-20"
              }
                min-w-[40px] min-h-[40px] rounded-fullest flex justify-center items-center`}
          >
            3
          </div>
        </div>
        {timeline === "1" ? (
          <>
            <div className="space-y-1">
              <label htmlFor="fName" className={labelStyle}>
                First Name
              </label>
              <input
                type="text"
                name="fName"
                id="fName"
                value={paForm.fName}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="lName" className={labelStyle}>
                Last Name
              </label>
              <input
                type="text"
                name="lName"
                id="lName"
                value={paForm.lName}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="email" className={labelStyle}>
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={paForm.email}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="password" className={labelStyle}>
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                value={paForm.password}
                onChange={onChange}
                autoComplete="off"
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="homeTel" className={labelStyle}>
                Home Telephone
              </label>
              <PhoneInput
                inputStyle={{
                  display: "block",
                  width: "100%",
                  paddingTop: "24px",
                  paddingBottom: "24px",
                }}
                inputProps={{ id: "homeTel", name: "homeTel" }}
                country="pk"
                value={homeTel}
                onChange={(homeTel) => setHomeTel(homeTel)}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="phone" className={labelStyle}>
                Phone
              </label>
              <PhoneInput
                inputStyle={{
                  display: "block",
                  width: "100%",
                  paddingTop: "24px",
                  paddingBottom: "24px",
                }}
                inputProps={{ id: "phone", name: "phone" }}
                country="pk"
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="address" className={labelStyle}>
                Address
              </label>
              <input
                type="text"
                name="address"
                id="address"
                value={paForm.address}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="postCode" className={labelStyle}>
                Postcode
              </label>
              <input
                type="text"
                name="postCode"
                id="postCode"
                value={paForm.postCode}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="gender" className={labelStyle}>
                Gender
              </label>
              <select
                name="gender"
                id="gender"
                value={paForm.gender}
                onChange={onChange}
                className={dropdownStyle}
              >
                <option value="">Select</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div className="space-y-1">
              <label htmlFor="dob" className={labelStyle}>
                Date of Birth
              </label>
              <input
                type="date"
                name="dob"
                id="dob"
                value={paForm.dob}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="sm:sm:col-span-2 flex justify-end gap-x-2">
              <button
                onClick={checkOne}
                className="bg-themeBlue py-2.5 w-40 text-white border border-themeBlue hover:text-themeBlue hover:bg-transparent"
              >
                Next
              </button>
            </div>
          </>
        ) : timeline === "2" ? (
          <>
            <div className="space-y-1">
              <label htmlFor="insurance" className={labelStyle}>
                National Insurance Number
              </label>
              <input
                type="number"
                name="insurance"
                id="insurance"
                value={paForm.insurance}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="dbs" className={labelStyle}>
                DBS Number
              </label>
              <input
                type="number"
                name="dbs"
                id="dbs"
                value={paForm.dbs}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="validFrom" className={labelStyle}>
                DBS Valid From
              </label>
              <input
                type="date"
                name="validFrom"
                id="validFrom"
                value={paForm.validFrom}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="validTo" className={labelStyle}>
                DBS Valid To
              </label>
              <input
                type="date"
                name="validTo"
                id="validTo"
                value={paForm.validTo}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="paStart" className={labelStyle}>
                PA Training Complete At
              </label>
              <input
                type="date"
                name="paStart"
                id="paStart"
                value={paForm.paStart}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="paEnd" className={labelStyle}>
                PA Training Expire At
              </label>
              <input
                type="date"
                name="paEnd"
                id="paEnd"
                value={paForm.paEnd}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="paAvail" className={labelStyle}>
                PA Training Availibility At
              </label>
              <input
                type="date"
                name="paAvail"
                id="paAvail"
                value={paForm.paAvail}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="lisence" className={labelStyle}>
                Lisence Number
              </label>
              <input
                type="number"
                name="lisence"
                id="lisence"
                value={paForm.lisence}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="issue" className={labelStyle}>
                ID Issue Date
              </label>
              <input
                type="date"
                name="issue"
                id="issue"
                value={paForm.issue}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="expiry" className={labelStyle}>
                ID Expiry Date
              </label>
              <input
                type="date"
                name="expiry"
                id="expiry"
                value={paForm.expiry}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="sm:sm:col-span-2 flex justify-end gap-x-2">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setTimeline("1");
                }}
                className="bg-themeBlue py-2.5 w-40 text-white border border-themeBlue hover:text-themeBlue hover:bg-transparent"
              >
                Previous
              </button>
              <button
                onClick={checkTwo}
                className="bg-themeBlue py-2.5 w-40 text-white border border-themeBlue hover:text-themeBlue hover:bg-transparent"
              >
                Next
              </button>
            </div>
          </>
        ) : timeline === "3" ? (
          <>
            <div className="space-y-1">
              <label htmlFor="dbsBack" className={labelStyle}>
                DBS Back Image
              </label>
              <input
                type="file"
                name="dbsBack"
                id="dbsBack"
                onChange={imgHandle}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="dbsFront" className={labelStyle}>
                DBS Front Image
              </label>
              <input
                type="file"
                name="dbsFront"
                id="dbsFront"
                onChange={imgHandle}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="profile" className={labelStyle}>
                Profile Image
              </label>
              <input
                type="file"
                name="profile"
                id="profile"
                onChange={imgHandle}
                className={inputStyle}
              />
            </div>
            <div className="sm:sm:col-span-2 flex justify-end gap-x-2">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setTimeline("2");
                }}
                className="bg-themeBlue py-2.5 w-40 text-white border border-themeBlue hover:text-themeBlue hover:bg-transparent"
              >
                Previous
              </button>
              <button
                type="submit"
                onClick={paFunc}
                className="bg-themeBlue py-2.5 w-40 text-white border border-themeBlue hover:text-themeBlue hover:bg-transparent"
              >
                Sign up
              </button>
            </div>
          </>
        ) : (
          <></>
        )}
      </form>
    </section>
  );
}
