import React from "react";
import { FiArrowUpRight } from "react-icons/fi";

export default function About4() {
  return (
    <section className="bg-themeBlue">
      <div className="lg:w-4/5 w-10/12 mx-auto grid md:grid-cols-12 gap-x-5">
        <div className="xl:col-span-8 md:col-span-7 space-y-4 flex items-center md:justify-start justify-center md:text-start text-center sm:py-16 py-8">
          <div className="flex flex-col justify-center md:items-start items-center gap-y-5">
            <h1 className="font-medium lg:text-2xl text-xl text-white">
              We’re looking for professional, driven people with integrity, who
              want to help drive us forward and make a lasting impact.
            </h1>
            <div>
              <button className="bg-white font-normal text-sm py-2 px-6 rounded-md flex items-center gap-x-2 border border-white hover:bg-transparent hover:text-white">
                <span>Explore open roles</span>
                <FiArrowUpRight size={16} />
              </button>
            </div>
          </div>
        </div>
        <div className="lg:col-span-4 md:col-span-5 flex items-end justify-end">
          <div>
            <img
              src="/images/about/sec4-img.webp"
              alt="couple"
              className="w-80"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
