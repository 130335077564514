// @ts-nocheck
import React from "react";
import { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { PostAPI } from "../../api/PostAPI";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  error_toaster,
  success_toaster,
} from "../../toaster/Toaster";
import { setLoginStatus } from "../../utilities/AuthCheck";
import { MiniLoader } from "../../loader/Loader";
import { firebase, auth } from "../../Firebase/firebase";

export default function Login() {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [loader, setLoader] = useState(false);
  const [otp, setotp] = useState("");
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState("");
  const sendOTP = () => {
    if (phone === "" || phone.length < 10) {
      error_toaster("Please fill correct number");
    } else {
      let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");
      auth
        .signInWithPhoneNumber("+" + phone, verify)
        .then((result) => {
          setfinal(result);
          success_toaster("Code Sent!");
          setshow(true);
        })
        .catch((err) => {
          error_toaster(err);
        });
    }
  };
  const validateOTP = () => {
    if (otp === null || final === null) {
      error_toaster("Please enter OTP");
    } else {
      setLoader(true);
      final
        .confirm(otp)
        .then(async (result) => {
          let res = await PostAPI("user/login", {
            phone: "+" + phone,
            device_token: "test",
          });
          if (res?.data.status) {
            localStorage.setItem("accessToken", res.data.access_token);
            localStorage.setItem(
              "userName",
              res?.data.data.f_name + " " + res?.data.data.l_name
            );
            setLoginStatus(true);
            success_toaster(res?.data.message);
            setLoader(false);
            navigate("/");
          } else {
            setLoader(false);
            error_toaster(res?.data.message);
          }
        })
        .catch((err) => {
          setLoader(false);
          error_toaster("Wrong code");
        });
    }
  };
  const labelStyle = "font-normal text-sm";
  const inputStyle =
    "bg-transparent block w-full border border-black border-opacity-20 font-normal text-base rounded py-3 px-5 focus:outline-none placeholder:font-light";
  return (
    <section className="h-screen flex flex-col gap-y-12 justify-center items-center relative after:content-[''] after:absolute after:bg-themeBlue after:w-full after:h-1/2 after:top-0 after:left-0">
      <Link
        to={"/"}
        className="font-medium sm:text-4xl text-3xl text-white text-center relative z-20"
      >
        Welcome to Allforcar
      </Link>
      <div className="xl:w-1/2 sm:w-3/4 w-11/12 mx-auto bg-white rounded-md shadow-around relative z-20">
        <div className="grid grid-cols-12 px-4">
          <div className="lg:col-span-8 sm:col-span-6 col-span-12 cols sm:py-12 py-6 relative">
            {loader ? (
              <MiniLoader />
            ) : show ? (
              <div className="lg:w-4/5 lg:mx-auto lg:pr-0 sm:pr-4 flex flex-col gap-y-7">
                <h5 className="font-medium text-2xl text-center">Enter OTP</h5>
                <div className="space-y-4">
                  <div className="space-y-1">
                    <div>
                      <label htmlFor="otp" className={labelStyle}>
                        OTP
                      </label>
                    </div>
                    <input
                      type="number"
                      name="otp"
                      id="otp"
                      placeholder={"Enter your OTP"}
                      onChange={(e) => {
                        setotp(e.target.value);
                      }}
                      className={inputStyle}
                    ></input>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    onClick={validateOTP}
                    className="bg-themeBlue text-white font-normal text-sm py-4 px-12 rounded-md border border-themeBlue hover:bg-transparent hover:text-themeBlue"
                  >
                    Sign In
                  </button>
                </div>
              </div>
            ) : (
              <div className="lg:w-4/5 lg:mx-auto lg:pr-0 sm:pr-4 flex flex-col gap-y-7">
                <h5 className="font-medium text-2xl text-center">Sign in</h5>
                <div className="space-y-4">
                  <div className="space-y-1">
                    <div>
                      <label htmlFor="phone" className={labelStyle}>
                        Phone
                      </label>
                    </div>
                    <PhoneInput
                      inputStyle={{
                        display: "block",
                        width: "100%",
                        paddingTop: "24px",
                        paddingBottom: "24px",
                      }}
                      inputProps={{ id: "phone", name: "phone" }}
                      country="pk"
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                      onEnterKeyPress={sendOTP}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-y-3 sm:w-fit">
                  <button
                    type="submit"
                    onClick={sendOTP}
                    className="bg-themeBlue text-white font-normal text-sm py-4 px-12 rounded-md border border-themeBlue hover:bg-transparent hover:text-themeBlue"
                  >
                    Send OTP
                  </button>
                  <Link
                    to={"/sign_up"}
                    className="font-normal text-sm text-center sm:hidden block"
                  >
                    Create Account
                  </Link>
                </div>
                <div id="recaptcha-container"></div>
              </div>
            )}
          </div>
          <div className="lg:col-span-4 sm:col-span-6 sm:block hidden relative">
            <div className="bg-themeSkin px-5 py-6 space-y-8 h-[110%] -mt-[7.5%] flex flex-col justify-center rounded-lg">
              <div className="space-y-4">
                <h1 className="font-medium text-sm uppercase text-center">
                  Sign up
                </h1>
                <p className="font-normal text-xs text-center">
                  New user? Sign up with us today to start your journey with
                  Allforcar
                </p>
              </div>
              <div className="space-y-3 w-10/12 mx-auto">
                <div className="flex items-center gap-x-4">
                  <AiOutlineCheck size={24} />
                  <span className="font-normal text-xs">
                    Book across web & app
                  </span>
                </div>
                <hr className="bg-black h-[1px] border-none" />
                <div className="flex items-center gap-x-4">
                  <AiOutlineCheck size={24} />
                  <span className="font-normal text-xs">
                    Earn loyalty points
                  </span>
                </div>
                <hr className="bg-black h-[1px] border-none" />
                <div className="flex items-center gap-x-4">
                  <AiOutlineCheck size={24} />
                  <span className="font-normal text-xs">
                    Exlusive discounts
                  </span>
                </div>
                <hr className="bg-black h-[1px] border-none" />
                <div className="flex items-center gap-x-4">
                  <AiOutlineCheck size={24} />
                  <span className="font-normal text-xs">
                    Quality assured service
                  </span>
                </div>
                <hr className="bg-black h-[1px] border-none" />
              </div>
              <div className="flex justify-center">
                <Link
                  to={"/sign_up"}
                  className="bg-white font-normal text-sm py-4 px-3 w-full text-center rounded-md border border-white"
                >
                  Sign up now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { firebase, auth } from '../../Firebase/firebase';

// const Login = () => {
//     // Inputs
//     const navigate = useNavigate();
//     const [mynumber, setnumber] = useState("");
//     const [otp, setotp] = useState('');
//     const [show, setshow] = useState(false);
//     const [final, setfinal] = useState('');

//     // Sent OTP
//     const signin = () => {

//         if (mynumber === "" || mynumber.length < 10) return;

//         let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
//         auth.signInWithPhoneNumber(mynumber, verify).then((result) => {
//             setfinal(result);
//             alert("code sent")
//             setshow(true);
//         })
//             .catch((err) => {
//                 alert(err);
//                 window.location.reload()
//             });
//     }

//     // Validate OTP
//     const ValidateOtp = () => {
//         if (otp === null || final === null)
//             return;
//         final.confirm(otp).then((result) => {
//             navigate("/");
//         }).catch((err) => {
//             alert("Wrong code");
//         })
//     }

//     return (
//         <div style={{ "marginTop": "200px" }}>
//             <center>
//                 <div style={{ display: !show ? "block" : "none" }}>
//                     <input value={mynumber} onChange={(e) => {
//                        setnumber(e.target.value) }}
//                         placeholder="phone number" />
//                     <br /><br />
//                     <div id="recaptcha-container"></div>
//                     <button onClick={signin}>Send OTP</button>
//                 </div>
//                 <div style={{ display: show ? "block" : "none" }}>
//                     <input type="text" placeholder={"Enter your OTP"}
//                         onChange={(e) => { setotp(e.target.value) }}></input>
//                     <br /><br />
//                     <button onClick={ValidateOtp}>Verify</button>
//                 </div>
//             </center>
//         </div>
//     );
// }

// export default Login;
