import React from "react";
import Section7Card from "./section7Elements/Section7Card";
import { AiOutlineCar } from "react-icons/ai";
import { BsHeart } from "react-icons/bs";
import { FiHeadphones } from "react-icons/fi";

export default function Section7() {
  return (
    <section className="sm:my-20 my-10">
      <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-8 xl:w-3/5 lg:w-4/5 w-10/12 mx-auto">
        <Section7Card
          Icon={AiOutlineCar}
          title="Safe and convenient"
          text="Moving with Allforcar is easy, convenient and fast."
          extend={true}
          extendText="Learn more about safety."
          extendTo="/"
        />
        <Section7Card
          Icon={BsHeart}
          title="Happy drivers, happy riders"
          text="Allforcar drivers earn more thanks to lower commission rates."
          extend={false}
        />
        <Section7Card
          Icon={FiHeadphones}
          title="Always there for you"
          text="Get fast support, whenever you need it."
          extend={false}
        />
      </div>
    </section>
  );
}
