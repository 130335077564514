import React from "react";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import Booking1 from "./booking1/Booking1";

export default function Booking() {
  return (
    <>
      <Header />
      <Booking1 />
      <Footer />
    </>
  );
}
