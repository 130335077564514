import React from "react";
import Mobile from "./section5Elements/Mobile";

export default function Section5() {
  return (
    <section className="bg-themeCreme sm:pt-12 py-6 text-center space-y-20">
      <div className="flex flex-col items-center gap-y-5 w-4/5 mx-auto">
        <h1 className="font-semibold text-3xl">Download the app</h1>
        <p className="font-normal text-base text-black text-opacity-60">
          Safer rides with Allforcar, the London experts
        </p>
        <div className="flex justify-center items-center gap-x-3">
          <div>
            <img src="/images/google.webp" alt="Play Store" className="w-36" />
          </div>
          <div>
            <img src="/images/appstore.webp" alt="App Store" className="w-36" />
          </div>
        </div>
      </div>
      <div className="sm:grid hidden sm:grid-cols-3 gap-x-8 gap-y-12 xl:w-1/2 lg:w-3/5 w-4/5 mx-auto">
        <Mobile text="Personal and business journeys" image="1" />
        <Mobile text="Pre-book and on-demand" image="2" />
        <Mobile text="24/7 UK based customer support" image="3" />
      </div>
    </section>
  );
}
