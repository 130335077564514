import React from "react";

export default function TimeLine(props) {
  return (
    <div className={`${props.height ? "h-28" : "h-auto"} space-y-1 py-0.5`}>
      <h6 className="font-medium sm:text-lg text-base">{props.title}</h6>
      <span className="font-extralight sm:text-sm text-xs text-white text-opacity-60">
        {props.text}
      </span>
    </div>
  );
}
