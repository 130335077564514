import React from "react";

export default function Contact2() {
  return (
    <section className="pb-60 lg:block hidden">
      <div className="grid grid-cols-2 gap-x-8 lg:w-4/5 w-10/12 mx-auto">
        <div className="-mt-12">
          <div>
            <img src="/images/about/sec2-img1.webp" alt="img" className="w-[420px]" />
          </div>
        </div>
      </div>
    </section>
  );
}
