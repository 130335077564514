import React from "react";

export default function Section2Card(props) {
  return (
    <div
      className={`bg-white pt-6 pb-8 xl:px-10 px-6 flex flex-col justify-center items-center gap-y-4 rounded-md border-t-8 ${props.topBorder}`}
    >
      <div className="w-fit bg-themeGray p-4 rounded-md">
        <img
          src={`/images/sec2-img${props.icon}.webp`}
          alt="icon"
          className="max-w-[40px]"
        />
      </div>
      <h2 className="font-semibold text-2xl text-center">{props.title}</h2>
      <p className="font-normal text-sm text-black text-opacity-60 text-center">
        {props.text}
      </p>
    </div>
  );
}
