import React from "react";
import Box from "./services2Elements/Box";
import { AiFillCar } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { FaWheelchair } from "react-icons/fa";
import { RiHospitalLine } from "react-icons/ri";
import { MdOutlineEditLocationAlt } from "react-icons/md";
import { GrSchedule } from "react-icons/gr";

export default function Services2() {
  return (
    <section className="bg-themeBlue2 sm:pb-16 pb-8">
      <div className="lg:w-4/5 w-10/12 mx-auto ">
        <div className="grid lg:grid-cols-2 gap-x-5 gap-y-7">
          <div className="flex flex-col justify-center lg:items-start items-center lg:text-start text-center gap-y-4 lg:py-12 lg:order-1 order-2">
            <h1 className="font-semibold text-3xl">
              For all kinds of ride needs
            </h1>
            <p className="font-light text-base">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
          <div className="flex lg:justify-end justify-center relative lg:order-2 order-1">
            <div className="-mt-6">
              <img
                src="/images/services/sec2-img.webp"
                alt="img"
                className="w-[420px] rounded-lg"
              />
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-3 xl:w-3/4 lg:w-4/5 lg:-mt-5 mt-8 relative z-20">
          <Box
            Icon={FiUsers}
            title="Community services"
            extend={true}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et . Quis ipsum suspendisse ultrices gravida. "
          />
          <Box
            Icon={RiHospitalLine}
            title="Hospital visits"
            extend={true}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. "
          />
          <Box
            Icon={MdOutlineEditLocationAlt}
            title="Trips & travel"
            extend={true}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
          />
          <div className="md:row-span-2">
            <Box
              Icon={GrSchedule}
              title="Events"
              extend={true}
              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            />
          </div>
          <Box Icon={GrSchedule} title="Premium cars" extend={false} />
          <Box Icon={FiUsers} title="Community services" extend={false} />
          <Box Icon={FiUsers} title="Community services" extend={false} />
          <Box Icon={FiUsers} title="Community services" extend={false} />
        </div>
        <div className="grid lg:grid-cols-2 mt-16 gap-x-5 gap-y-7 lg:mx-5">
          <div className="flex items-center lg:justify-start justify-center">
            <div>
              <img
                src="/images/services/sec2-img2.webp"
                alt="img"
                className="w-[420px] rounded-lg"
              />
            </div>
          </div>
          <div className="flex flex-col justify-center lg:items-start items-center lg:text-start text-center gap-y-2">
            <h1 className="font-semibold text-3xl">
              For all kinds of ride needs
            </h1>
            <p className="font-light text-base">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        </div>
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-3 lg:-mt-5 mt-8">
          <Box
            Icon={FaWheelchair}
            title="Passenger assistants"
            extend={true}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. "
          />
          <Box
            Icon={FiUsers}
            title="Community services"
            extend={true}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. "
          />
          <Box
            Icon={FiUsers}
            title="Community services"
            extend={true}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. "
          />
          <Box
            Icon={FiUsers}
            title="Community services"
            extend={true}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. "
          />
        </div>
        <div className="grid lg:grid-cols-2 gap-x-5 gap-y-7 mt-16">
          <div className="flex flex-col justify-center lg:items-start items-center lg:text-start text-center gap-y-2">
            <h1 className="font-semibold text-3xl">
              Services tailored for you
            </h1>
            <p className="font-light text-base">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div className="grid sm:grid-cols-2 gap-3 mt-4">
              <Box
                Icon={AiFillCar}
                title="Vehicle insurance"
                extend={true}
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. "
              />
              <Box
                Icon={FiUsers}
                title="Community services"
                extend={true}
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. "
              />
            </div>
          </div>
          <div className="flex items-center lg:justify-end justify-center">
            <div>
              <img
                src="/images/services/sec2-img2.webp"
                alt="img"
                className="w-[420px] rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
