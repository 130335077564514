// @ts-nocheck
import React, { useState } from "react";
import OrderCard from "./myTripsElements/OrderCard";
import TripDetails from "./myTripsElements/TripDetails";
import GetAPI from "../../../api/GetAPI";

export default function MyTrips() {
  const [toggle, setToggle] = useState("scheduled");
  const [details, setDetails] = useState(false);
  const scheduled = GetAPI("get_scheduled_requests");
  const cancelled = GetAPI("request_history_cancel");
  const completed = GetAPI("request_history_complete");
  return (
    <section className="lg:w-1/2 md:w-3/4 sm:10/12 w-11/12 mx-auto my-12 space-y-12">
      {details ? (
        <TripDetails
          onClick={() => {
            setDetails(false);
          }}
        />
      ) : (
        <>
          <div className="grid grid-cols-3">
            <button
              onClick={() => {
                setToggle("scheduled");
              }}
              className={`${
                toggle === "scheduled"
                  ? "bg-themeBlue text-white border-themeBlue"
                  : "bg-white text-black text-opacity-60 border-black border-opacity-20"
              } w-full py-3 px-5 font-normal text-base text-center border rounded-l-md`}
            >
              Scheduled
            </button>
            <button
              onClick={() => {
                setToggle("completed");
              }}
              className={`${
                toggle === "completed"
                  ? "bg-themeBlue text-white border-themeBlue"
                  : "bg-white text-black text-opacity-60 border-black border-opacity-20"
              } w-full py-3 px-5 font-normal text-base text-center border`}
            >
              Completed
            </button>
            <button
              onClick={() => {
                setToggle("cancelled");
              }}
              className={`${
                toggle === "cancelled"
                  ? "bg-themeBlue text-white border-themeBlue"
                  : "bg-white text-black text-opacity-60 border-black border-opacity-20"
              } w-full py-3 px-5 font-normal text-base text-center border rounded-r-md`}
            >
              Cancelled
            </button>
          </div>
          <div className="space-y-5">
            {toggle === "scheduled"
              ? scheduled?.data?.data?.map((ele, index) => (
                  <OrderCard
                    key={index}
                    type="Trip"
                    date={ele?.schedule_date + " " + ele?.schedule_time}
                    from={ele?.pickup_address}
                    to={ele?.drop_address}
                    status="Upcoming"
                    // onDetails={() => {
                    //   setDetails(true);
                    // }}
                  />
                ))
              : toggle === "completed"
              ? completed?.data?.data?.map((ele, index) => (
                  <OrderCard
                    key={index}
                    type="Trip"
                    date={ele?.schedule_date + " " + ele?.schedule_time}
                    from={ele?.pickup_address}
                    to={ele?.drop_address}
                    status="Upcoming"
                    fair={ele?.total}
                    // onDetails={() => {
                    //   setDetails(true);
                    // }}
                  />
                ))
              : cancelled?.data?.data?.map((ele, index) => (
                  <OrderCard
                    key={index}
                    type="Trip"
                    from={ele?.pickup_address}
                    to={ele?.drop_address}
                    status="Cancelled"
                    fair={ele?.total}
                    // onDetails={() => {
                    //   setDetails(true);
                    // }}
                  />
                ))}
          </div>
        </>
      )}
    </section>
  );
}
