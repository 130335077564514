import React from "react";
import Section6Card from "./section6Elements/Section6Card";

export default function Section6() {
  return (
    <section className="sm:my-20 my-10">
      <div className="grid md:grid-cols-2 gap-8 xl:w-3/5 lg:w-4/5 w-10/12 mx-auto">
        <Section6Card
          img="1"
          title="Open a business account"
          para="Providing safety, ease-of-use and flexibility for businesses to manage how their employees travel. Ready to start travelling with us? "
          action="Open an account"
          to="/pa_signup"
        />
        <Section6Card
          img="2"
          title="Ride with us"
          para="Come Drive with Us. Apply today to start your new career and join our dedicated fleet of trained, qualified drivers and couriers."
          action="Drive with us"
          to="/driver"
        />
      </div>
    </section>
  );
}
