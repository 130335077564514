// @ts-nocheck
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaEdit, FaRegEnvelope, FaUserAlt } from "react-icons/fa";
import { MdPhoneIphone } from "react-icons/md";
import {
  error_toaster,
  info_toaster,
  success_toaster,
} from "../../../toaster/Toaster";
import { PostAPI } from "../../../api/PostAPI";
import GetAPI from "../../../api/GetAPI";
import { useNavigate } from "react-router-dom";

export default function ProfileSettings() {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const { data, reFetch } = GetAPI("user/profile");
  const [editProfile, setEditProfile] = useState({
    fName: "",
    lName: "",
  });
  const editFunc = async (e) => {
    e.preventDefault();
    let res = await PostAPI("user/update", {
      f_name: editProfile.fName,
      l_name: editProfile.lName,
    });
    if (editProfile.fName === "") {
      info_toaster("Please Fill First Name");
    } else if (editProfile.lName === "") {
      info_toaster("Please Fill Last Name");
    } else if (res?.data?.status) {
      localStorage.setItem(
        "userName",
        editProfile.fName + " " + editProfile.lName
      );
      success_toaster(res?.data?.message);
      setEdit(false);
      setEditProfile({ fName: "", lName: "" });
      reFetch();
      navigate("/user_dashboard");
    } else {
      error_toaster(res?.data?.message);
    }
  };
  useEffect(() => {
    reFetch();
  }, [editFunc]);

  const onChange = (e) => {
    setEditProfile({ ...editProfile, [e.target.name]: e.target.value });
  };
  const [xs] = useMediaQuery("(min-width: 480px)");
  const labelStyle = "font-normal text-sm";
  const inputStyle =
    "block w-full rounded px-5 py-2 bg-transparent border border-black border-opacity-20 font-normal text-sm focus:outline-none";
  return data.length === 0 ? (
    <></>
  ) : (
    <>
      <Modal
        onClose={() => {
          setEdit(false);
          setEditProfile({ fName: "", lName: "" });
        }}
        isOpen={edit}
        isCentered
        size={xs ? "md" : "xs"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <ModalCloseButton />
            <div className="sm:px-6 px-2 py-8">
              <h3 className="font-semibold text-lg">
                Update profile information
              </h3>
              <hr className="h-0.5 bg-black bg-opacity-20 border-none my-2" />
              <form className="mt-4 space-y-3">
                <div className="space-y-1">
                  <label htmlFor="fName" className={labelStyle}>
                    First name
                  </label>
                  <input
                    value={editProfile.fName}
                    onChange={onChange}
                    type="text"
                    name="fName"
                    id="fName"
                    placeholder="First Name"
                    className={inputStyle}
                  />
                </div>
                <div className="space-y-1">
                  <label htmlFor="lName" className={labelStyle}>
                    Last name
                  </label>
                  <input
                    value={editProfile.lName}
                    onChange={onChange}
                    type="text"
                    name="lName"
                    id="lName"
                    placeholder="Last Name"
                    className={inputStyle}
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    onClick={editFunc}
                    className="bg-themeBlue py-2 px-5 rounded font-normal text-sm text-white border border-themeBlue hover:bg-transparent hover:text-themeBlue"
                  >
                    Save & update
                  </button>
                </div>
              </form>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <section className="lg:w-1/2 md:w-3/4 sm:10/12 w-11/12 mx-auto my-12 space-y-4">
        <div className="flex sm:flex-row flex-col sm:items-center justify-between gap-y-4">
          <div className="flex items-center gap-x-4">
            <div className="w-fit bg-themeBlue rounded-fullest p-4 text-white">
              <FaUserAlt size={28} />
            </div>
            <div className="space-y-2">
              <h3 className="font-medium text-xl">
                {data?.data?.f_name + " " + data?.data?.l_name}
              </h3>
              <div className="flex sm:flex-row flex-col sm:items-center gap-x-5 gap-y-2">
                <p className="font-normal text-sm text-black text-opacity-60 flex items-center gap-x-2">
                  <FaRegEnvelope size={20} />
                  <span>{data?.data?.email}</span>
                </p>
                <p className="font-normal text-sm text-black text-opacity-60 flex items-center gap-x-2">
                  <MdPhoneIphone size={20} />
                  <span>{data?.data?.phone}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-3 sm:items-start items-end">
            <button
              onClick={() => {
                setEdit(true);
              }}
              className="font-medium text-base text-black text-opacity-60 flex items-center gap-x-2"
            >
              <FaEdit size={20} />
              <span>Edit profile</span>
            </button>
          </div>
        </div>
        <hr className="bg-black bg-opacity-20 h-0.5 border-none" />
        {/* <div className="flex sm:flex-row flex-col justify-between items-center pt-8">
          <h3 className="font-medium text-xl">Saved locations</h3>
          <button
            onClick={() => {
              setLocation(true);
            }}
            className="bg-transaprent text-themeBlue font-normal text-base sm:w-auto w-full flex justify-center items-center gap-x-1 border border-themeBlue py-2 px-6 rounded hover:bg-themeBlue hover:text-white"
          >
            <BsPlus size={24} />
            <span>Add new location</span>
          </button>
        </div>
        <div className="border-t-4 border-b border-x border-t-themeBlue border-b-black border-x-black px-5 pt-8 pb-16 space-y-5 rounded shadow-lg">
          <Address
            title="Home"
            address="Street #2 K block Main blvd johar town, Lahore"
          />
          <Address
            title="Office"
            address="K block Main blvd johar town, Lahore"
          />
        </div> */}
      </section>
    </>
  );
}
