import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../api/API"
const GetAPI = (url) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    var config = {
      headers: {
        accessToken: localStorage.getItem("accessToken"),
        Authorization: 'Bearer ' + localStorage.getItem("accessToken")
      },
    };
    setLoading(true);
    const fetchData = () => {
      axios.get(BASE_URL + url, config).then((dat) => {
        setData(dat.data);
      });
    };
    fetchData();
    setLoading(false);
  }, [url]);

  const reFetch = async () => {
    var config = {
      headers: {
        accessToken: localStorage.getItem("accessToken"),
        Authorization: 'Bearer ' + localStorage.getItem("accessToken")
      },
    };
    setLoading(true);
    try {
      axios.get(BASE_URL + url, config).then((dat) => {
        setData(dat.data);
      });
    } catch (err) { }
    setLoading(false);
  };

  return { data, loading, reFetch };
};

export default GetAPI;
