import React from "react";

export default function MiniSection(props) {
  return (
    <div className="flex flex-col justify-center items-center gap-y-2 text-center">
      <div>
        <img
          src={`/images/about/sec1-icon${props.icon}.webp`}
          alt="icon"
          className="w-12"
        />
      </div>
      <h2 className="font-medium text-xl">{props.title}</h2>
      <p className="font-normal text-sm text-black text-opacity-60">
        {props.para}
      </p>
    </div>
  );
}
