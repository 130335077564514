import React from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import Contact1 from "./contact1/Contact1";
import Contact2 from "./contact2/Contact2";

export default function Contact() {
  return (
    <>
      <Header />
      <Contact1 />
      <Contact2 />
      <Footer />
    </>
  );
}
