import React from "react";
import { BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

export default function Footer() {
  const liHeadStyle = "font-medium text-base sm:mb-3 mb-2";
  const liStyle = "font-light text-sm sm:mt-2 mt-1";
  return (
    <footer className="bg-themeBlue2 sm:py-8 py-4">
      <div className="xl:w-3/5 lg:w-4/5 w-10/12 mx-auto sm:space-y-16 space-y-8">
        <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 sm:gap-y-6 gap-y-4">
          <div className="flex order-1">
            <ul>
              <li className={liHeadStyle}>Book</li>
              <li className={liStyle}>Download the app</li>
              <li className={liStyle}>Sign up</li>
              <li className={liStyle}>Off peak fares</li>
              <li className={liStyle}>Free calculator</li>
            </ul>
          </div>
          <div className="flex sm:order-2 order-3">
            <ul>
              <li className={liHeadStyle}>Services</li>
              <li className={liStyle}>Premium cars</li>
              <li className={liStyle}>Courier services</li>
              <li className={liStyle}>Com cabs</li>
              <li className={liStyle}>UK services</li>
              <li className={liStyle}>Events</li>
              <li className={liStyle}>Same day courier</li>
              <li className={liStyle}>Coach hire</li>
            </ul>
          </div>
          <div className="flex sm:justify-center sm:order-3 order-4">
            <ul>
              <li className={liHeadStyle}>Work with us</li>
              <li className={liStyle}>Brand partnership</li>
              <li className={liStyle}>Become a driver</li>
              <li className={liStyle}>Become a personal assistant</li>
              <li className={liStyle}>Careers</li>
              <li className={liStyle}>Business account</li>
              <li className={liStyle}>Personal account</li>
            </ul>
          </div>
          <div className="flex md:justify-center sm:order-4 order-2">
            <ul>
              <li className={liHeadStyle}>Company</li>
              <li className={liStyle}>About us</li>
              <li className={liStyle}>Brand</li>
              <li className={liStyle}>FAQ's</li>
              <li className={liStyle}>Help & Support</li>
              <li className={liStyle}>Contact us</li>
            </ul>
          </div>
          <div className="flex lg:justify-end order-5">
            <ul>
              <li className={liHeadStyle}>Work with us</li>
              <li className={liStyle}>Brand partnership</li>
              <li className={liStyle}>Become a driver</li>
              <li className={liStyle}>Careers</li>
              <li className={liStyle}>Business Account</li>
              <li className={liStyle}>Personal Account</li>
            </ul>
          </div>
        </div>
        <div className="space-y-4">
          <div className="flex md:flex-row flex-col md:justify-between md:items-center gap-y-3">
            <div className="flex items-center gap-x-3">
              <span className="font-normal text-base">Follow us</span>
              <div className="w-fit p-2 rounded-fullest border border-black border-opacity-80 hover:bg-black hover:text-white">
                <FaFacebookF size={16} />
              </div>
              <div className="w-fit p-2 rounded-fullest border border-black border-opacity-80 hover:bg-black hover:text-white">
                <BsYoutube size={16} />
              </div>
              <div className="w-fit p-2 rounded-fullest border border-black border-opacity-80 hover:bg-black hover:text-white">
                <BsTwitter size={16} />
              </div>
              <div className="w-fit p-2 rounded-fullest border border-black border-opacity-80 hover:bg-black hover:text-white">
                <BsInstagram size={16} />
              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <span className="font-normal text-base">Download the app</span>
              <div>
                <img
                  src="/images/google.webp"
                  alt="playstore"
                  className="w-28"
                />
              </div>
              <div>
                <img
                  src="/images/appstore.webp"
                  alt="appstore"
                  className="w-28"
                />
              </div>
            </div>
          </div>
          <hr className="border-none h-1 bg-black bg-opacity-60 rounded-xl" />
          <div className="flex sm:flex-row flex-col sm:items-center gap-x-8 gap-y-2">
            <p className="font-light text-xs">&copy; 2020 - 2023 Allforcar</p>
            <span className="font-light text-xs underline">
              Terms of services
            </span>
            <span className="font-light text-xs underline">Privacy policy</span>
            <span className="font-light text-xs underline">
              Cookies settings
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
