import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import About from './components/pages/about/About'
import Driver from './components/pages/driver/Driver'
import Login from './components/pages/auth/Login'
import Signup from './components/pages/auth/Signup'
import VerifyPhone from './components/pages/auth/VerifyPhone'
import Booking from './components/pages/booking/Booking'
import Contact from './components/pages/contact/Contact'
import Dashboard from './components/pages/dashboard/Dashboard'
import Home from './components/pages/home/Home'
import PA from './components/pages/pa/PA'
import Services from './components/pages/services/Services'
import ProtectedRoute from './components/utilities/ProtectedRoute'

export default function App() {
  return (
    <section className='font-soehne'>
      <ToastContainer />
      <ChakraProvider>
        <Router>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/services' element={<Services />} />
            <Route path='/contact_us' element={<Contact />} />
            <Route path='/pa_signup' element={<PA />} />
            <Route path='/driver' element={<Driver />} />
            <Route path='/booking' element={<ProtectedRoute Component={Booking} />} />
            <Route path='/user_dashboard' element={<ProtectedRoute Component={Dashboard} />} />
            <Route path='/sign_in' element={<Login />} />
            <Route path='/sign_up' element={<Signup />} />
            <Route path='/verify_phone' element={<VerifyPhone />} />
          </Routes>
        </Router>
      </ChakraProvider>
    </section>
  )
}
