import React from "react";

export default function TabHead(props) {
  const { Icon } = props;
  return (
    <button
      onClick={props.onClick}
      className={`bg-themeBlue3 sm:px-5 px-3 py-2 flex justify-center items-center border-b-[6px] ${
        props.active ? "border-themeBlue" : "border-transparent"
      } `}
    >
      <h2
        className={`font-medium sm:text-lg text-base ${
          props.active ? "text-themeBlue" : "text-black text-opacity-60"
        } flex items-center gap-x-3`}
      >
        <Icon size={24} className="sm:block hidden" />
        <span>{props.tabTitle}</span>
      </h2>
    </button>
  );
}
