import React from "react";
import { Link } from "react-router-dom";

export default function Section7Card(props) {
  const { Icon } = props;
  return (
    <div className="p-5 flex flex-col sm:items-start items-center sm:text-start text-center gap-y-4 rounded-lg shadow-lg">
      <div className="bg-themeBlue2 w-fit p-5 rounded-md">
        <Icon size={28} />
      </div>
      <h6 className="font-medium text-base">{props.title}</h6>
      <p className="font-normal text-xs">
        <span className="text-black text-opacity-60">{props.text}</span>
        <br />
        {props.extend && (
          <Link to={props.extendTo} className="text-themeBlue">
            {props.extendText}
          </Link>
        )}
      </p>
    </div>
  );
}
