
import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyCvICF3URqsUR2sLuAzu2z4GIoetuZ6GfU",
    authDomain: "http://allfor-cars.firebaseapp.com/",
    projectId: "allfor-cars",
    storageBucket: "http://allfor-cars.appspot.com/",
    messagingSenderId: "427426629606",
    appId: "1:427426629606:web:23042d86b45f6953bb7827"
};

firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
export { auth, firebase };