import React from "react";
import { Link } from "react-router-dom";

export default function VerifyPhone() {
  return (
    <section className="h-screen flex flex-col gap-y-12 justify-center items-center relative after:content-[''] after:absolute after:bg-themeBlue after:w-full after:h-1/2 after:top-0 after:left-0">
      <h1 className="font-medium sm:text-4xl text-3xl text-white text-center relative z-20">
        Almost there..
      </h1>
      <div className="xl:w-1/2 sm:w-3/4 w-11/12 mx-auto bg-white rounded-md shadow-around relative z-20 py-8 sm:px-10 px-4">
        <div className="sm:w-3/4 mx-auto flex flex-col gap-y-4 items-center text-center">
          <h1 className="font-semibold text-3xl">Confirm your number</h1>
          <p className="font-normal text-sm">
            A four-digit verification code has been sent to your provided mobile
            number. Please confirm yhe number is yours by entering the code
            below
          </p>
          <div>
            <label htmlFor="verify" className="font-normal text-sm">
              Verify the code
            </label>
            <input
              type="number"
              name="verify"
              id="verify"
              className="font-medium text-xl text-center rounded px-5 py-2 bg-transparent border-b-4 border-themeBlue focus:outline-none focus:b block sm:w-96 w72"
            />
          </div>
          <div className="mt-3">
            <button className="bg-themeBlue text-white font-normal text-sm py-4 px-12 rounded-md border border-themeBlue hover:bg-transparent hover:text-themeBlue">
              Verify code
            </button>
          </div>
          <p className="font-light text-base">
            Haven’t received the code?{" "}
            <Link to={"/sign_up"} className="text-themeBlue">
              Change number
            </Link>{" "}
            or <button className="text-themeBlue">Resend</button>
          </p>
        </div>
      </div>
    </section>
  );
}
