import React from "react";

export default function Box(props) {
  const { Icon } = props;
  return (
    <div className="bg-white rounded-md px-5 py-4 flex flex-col justify-center sm:items-start items-center sm:text-start text-center gap-y-3 h-full">
      <Icon size={32} />
      <h4 className="font-medium text-sm">{props.title}</h4>
      {props.extend && <p className="font-light text-xs">{props.text}</p>}
    </div>
  );
}
