import React from "react";
import { BsCurrencyPound } from "react-icons/bs";
import { HiUsers } from "react-icons/hi";

export default function Label(props) {
  const style = "input[type='radio']:checked+label {border-color: #296BA5}";
  return (
    <>
      <style>{style}</style>
      <input
        onChange={props.onChange}
        type="radio"
        name={props.inputName}
        id={props.for}
        value={props.inputValue}
        className="hidden"
      />
      <label
        htmlFor={props.for}
        className={`grid sm:grid-cols-12 bg-white py-4 px-5 rounded-md border-2 border-white cursor-pointer`}
      >
        <div className="sm:col-span-7 space-y-1">
          <div className="flex items-center gap-x-3 pb-4">
            <div className="flex items-center gap-x-2 font-medium text-sm bg-themeGray2 py-1 px-2 rounded">
              <HiUsers size={16} />
              <span>{props.passengers}</span>
            </div>
            <div className="flex items-center font-medium text-sm bg-themeGray2 py-1 px-2 rounded">
              <BsCurrencyPound size={16} />
              <span>{props.price}</span>
            </div>
          </div>
          <h5 className="font-medium text-base">{props.type}</h5>
          <p className="font-normal text-xs text-black text-opacity-60">
            {props.text}
          </p>
        </div>
        <div className="sm:col-span-5 flex justify-center items-center">
          <img
            src={props.img}
            alt="car"
            className="w-40 h-40 object-contain"
          />
        </div>
      </label>
    </>
  );
}
