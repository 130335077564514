import React from "react";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import PA1 from "./pa1/PA1";

export default function PA() {
  return (
    <>
      <Header />
      <PA1 />
      <Footer />
    </>
  );
}
