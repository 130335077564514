// @ts-nocheck
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { PostAPI2 } from "../../../api/PostAPI";
import {
  error_toaster,
  info_toaster,
  success_toaster,
} from "../../../toaster/Toaster";

export default function PA1() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [homeTel, setHomeTel] = useState("");
  const [phone, setPhone] = useState("");
  const [driverForm, setDriverForm] = useState({
    fName: "",
    lName: "",
    gender: "",
    dob: "",
    password: "",
    insurance: "",
    email: "",
    dbs: "",
    dbsValidFrom: "",
    dbsValidTo: "",
    availibility: "",
    idIssue: "",
    idExpiry: "",
    registration: "",
    motIssue: "",
    motExpiry: "",
    phv: "",
    phvValidFrom: "",
    phvValidTo: "",
    licence: "",
    vehicle: "",
    drivingValidFrom: "",
    drivingValidTo: "",
    vehicleCompany: "",
    vehicleModel: "",
    vehicleYear: "",
    service: "",
    address: "",
    postCode: "",
    profile: "",
    vehicleImage: "",
    vehicleRegImage: "",
    licenseFrontImage: "",
    licenseBackImage: "",
    phvFrontImage: "",
    phvBackImage: "",
    logbook: "",
  });
  const onChange = (e) => {
    setDriverForm({ ...driverForm, [e.target.name]: e.target.value });
  };
  const imgHandle = (e) => {
    setDriverForm({ ...driverForm, [e.target.name]: e.target.files[0] });
  };
  const checkOne = (e) => {
    e.preventDefault();
    if (driverForm.fName === "") {
      info_toaster("Please fill First Name field");
    } else if (driverForm.lName === "") {
      info_toaster("Please fill Last Name field");
    } else if (driverForm.gender === "") {
      info_toaster("Please select your Gender");
    } else if (driverForm.dob === "") {
      info_toaster("Please enter your Date of Birth");
    } else if (phone < 4) {
      info_toaster("Please fill Phone Number field");
    } else if (driverForm.password === "") {
      info_toaster("Please fill Password field");
    } else if (homeTel < 4) {
      info_toaster("Please fill Home Telephone Number field");
    } else if (driverForm.insurance === "") {
      info_toaster("Please fill National Insurance Number field");
    } else if (driverForm.email === "") {
      info_toaster("Please enter your Email");
    } else {
      setTimeline("2");
    }
  };
  const checkTwo = (e) => {
    e.preventDefault();
    if (driverForm.dbs === "") {
      info_toaster("Please enter DBS Number");
    } else if (driverForm.dbsValidFrom === "") {
      info_toaster("Please enter DBS Validation date");
    } else if (driverForm.dbsValidTo === "") {
      info_toaster("Please enter DBS Validation date");
    } else if (driverForm.availibility === "") {
      info_toaster("Please select Availibility Date");
    } else if (driverForm.idIssue === "") {
      info_toaster("Please enter ID Issue Date");
    } else if (driverForm.idExpiry === "") {
      info_toaster("Please enter ID Expiry Date");
    } else if (driverForm.registration === "") {
      info_toaster("Please enter Vehicle Registration Number");
    } else if (driverForm.motIssue === "") {
      info_toaster("Please enter MOT Issue Date");
    } else if (driverForm.motExpiry === "") {
      info_toaster("Please enter MOT Expiry Date");
    } else if (driverForm.phv === "") {
      info_toaster("Please enter PHV License Number");
    } else if (driverForm.phvValidFrom === "") {
      info_toaster("Please enter PHV License Issue Date");
    } else if (driverForm.phvValidTo === "") {
      info_toaster("Please enter PHV License Expiry Date");
    } else {
      setTimeline("3");
    }
  };
  const checkThree = (e) => {
    e.preventDefault();
    if (driverForm.licence === "") {
      info_toaster("Please enter Licence Number");
    } else if (driverForm.vehicle === "") {
      info_toaster("Please enter Vehicle Number");
    } else if (driverForm.drivingValidFrom === "") {
      info_toaster("Please enter Driving License Issue Date");
    } else if (driverForm.drivingValidTo === "") {
      info_toaster("Please enter Driving License Expiry Date");
    } else if (driverForm.vehicleCompany === "") {
      info_toaster("Please enter Vehicle Company");
    } else if (driverForm.vehicleModel === "") {
      info_toaster("Please enter Vehicle Model");
    } else if (driverForm.vehicleYear === "") {
      info_toaster("Please enter Import Year of your Vehicle");
    } else if (driverForm.service === "") {
      info_toaster("Please enter Service ID");
    } else if (driverForm.address === "") {
      info_toaster("Please enter your Address");
    } else if (driverForm.postCode === "") {
      info_toaster("Please enter Postcode");
    } else {
      setTimeline("4");
    }
  };
  const driverFunc = async (e) => {
    e.preventDefault();
    let res = await PostAPI2("driver/signup", {
      first_name: driverForm.fName,
      last_name: driverForm.lName,
      gender: driverForm.gender,
      date_of_birth: driverForm.dob,
      phone: phone,
      password: driverForm.password,
      home_telephone: homeTel,
      email: driverForm.email,
      national_insurance_number: driverForm.insurance,
      DBS_number: driverForm.dbs,
      dbs_valid_from: driverForm.dbsValidFrom,
      dbs_valid_to: driverForm.dbsValidTo,
      driver_available_at: driverForm.availibility,
      id_issue_date: driverForm.idIssue,
      id_expiry_date: driverForm.idExpiry,
      vehicle_registration_number: driverForm.registration,
      mot_issue_date: driverForm.motIssue,
      mot_expiry_date: driverForm.motExpiry,
      phv_license_number: driverForm.phv,
      phv_license_valid_from: driverForm.phvValidFrom,
      phv_license_valid_to: driverForm.phvValidTo,
      license_no: driverForm.licence,
      vehicle_no: driverForm.vehicle,
      driving_license_valid_from: driverForm.drivingValidFrom,
      driving_license_valid_to: driverForm.drivingValidTo,
      vehicle_company: driverForm.vehicleCompany,
      vehicle_modal: driverForm.vehicleModel,
      vehicle_year: driverForm.vehicleYear,
      service_id: driverForm.service,
      address: driverForm.address,
      postcode: driverForm.postCode,
      driver_image: driverForm.profile,
      vehicle_image: driverForm.vehicleImage,
      vehicle_registration_image: driverForm.vehicleRegImage,
      license_front_image: driverForm.licenseFrontImage,
      license_back_image: driverForm.licenseBackImage,
      phv_license_photo_front: driverForm.phvFrontImage,
      phv_license_photo_back: driverForm.phvBackImage,
      logbook_image: driverForm.logbook,
      device_token: "test",
    });
    if (driverForm.profile === "") {
      info_toaster("Please enter Profile Image");
    } else if (driverForm.vehicleImage === "") {
      info_toaster("Please enter Vehicle Image");
    } else if (driverForm.vehicleRegImage === "") {
      info_toaster("Please enter Vehicle Registration Imagee");
    } else if (driverForm.licenseFrontImage === "") {
      info_toaster("Please enter License Front Image");
    } else if (driverForm.licenseBackImage === "") {
      info_toaster("Please enter License Back Image");
    } else if (driverForm.phvFrontImage === "") {
      info_toaster("Please enter PHV License Front Image");
    } else if (driverForm.phvBackImage === "") {
      info_toaster("Please enter PHV License Back Image");
    } else if (driverForm.logbook === "") {
      info_toaster("Please enter Logbook Image");
    } else if (res?.data?.status) {
      setLoader(false);
      navigate("/");
      success_toaster(res?.data?.message);
    } else {
      setLoader(false);
      error_toaster(res?.data?.message);
    }
  };
  const [timeline, setTimeline] = useState("1");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [timeline]);
  const labelStyle = "font-normal md:text-base text-sm";
  const inputStyle =
    "bg-white block w-full px-4 py-3 border border-black border-opacity-20 rounded font-normal text-base focus:outline-none placeholder:text-opacity-40";
  const dropdownStyle =
    "bg-white block w-full px-4 py-4 border border-black border-opacity-20 rounded font-normal text-sm cursor-pointer focus:outline-none placeholder:text-opacity-40";
  return (
    <section className="pt-8 pb-16">
      <form className="lg:w-4/5 sm:w-10/12 w-11/12 mx-auto bg-themeGray2 rounded-xl py-12 px-8 grid sm:grid-cols-2 gap-x-5 gap-y-4">
        <h1 className="sm:sm:col-span-2 text-4xl font-bold text-center text-black text-opacity-80">
          Signup for Driver Account
        </h1>
        <div className="col-span-2 flex items-center text-lg my-4">
          <div
            className={`
              ${
                timeline === "1" ||
                timeline === "2" ||
                timeline === "3" ||
                timeline === "4"
                  ? "bg-themeBlue text-white"
                  : "bg-black bg-opacity-20"
              }
                min-w-[40px] min-h-[40px] rounded-fullest flex justify-center items-center`}
          >
            1
          </div>
          <div
            className={`${
              timeline === "2" || timeline === "3" || timeline === "4"
                ? "border-y-themeBlue"
                : "border-y-black border-opacity-20"
            } w-full h-0 border-y mx-4`}
          ></div>
          <div
            className={`
              ${
                timeline === "2" || timeline === "3" || timeline === "4"
                  ? "bg-themeBlue text-white"
                  : "bg-black bg-opacity-20"
              }
                min-w-[40px] min-h-[40px] rounded-fullest flex justify-center items-center`}
          >
            2
          </div>
          <div
            className={`${
              timeline === "3" || timeline === "4"
                ? "border-y-themeBlue"
                : "border-y-black border-opacity-20"
            } w-full h-0 border-y mx-4`}
          ></div>
          <div
            className={`
              ${
                timeline === "3" || timeline === "4"
                  ? "bg-themeBlue text-white"
                  : "bg-black bg-opacity-20"
              }
                min-w-[40px] min-h-[40px] rounded-fullest flex justify-center items-center`}
          >
            3
          </div>
          <div
            className={`${
              timeline === "4"
                ? "border-y-themeBlue"
                : "border-y-black border-opacity-20"
            } w-full h-0 border-y mx-4`}
          ></div>
          <div
            className={`
              ${
                timeline === "4"
                  ? "bg-themeBlue text-white"
                  : "bg-black bg-opacity-20"
              }
                min-w-[40px] min-h-[40px] rounded-fullest flex justify-center items-center`}
          >
            4
          </div>
        </div>
        {timeline === "1" ? (
          <>
            <div className="space-y-1">
              <label htmlFor="fName" className={labelStyle}>
                First Name
              </label>
              <input
                type="text"
                name="fName"
                id="fName"
                value={driverForm.fName}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="lName" className={labelStyle}>
                Last Name
              </label>
              <input
                type="text"
                name="lName"
                id="lName"
                value={driverForm.lName}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="gender" className={labelStyle}>
                Gender
              </label>
              <select
                name="gender"
                id="gender"
                value={driverForm.gender}
                onChange={onChange}
                className={dropdownStyle}
              >
                <option value="">Select</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div className="space-y-1">
              <label htmlFor="dob" className={labelStyle}>
                Date of Birth
              </label>
              <input
                type="date"
                name="dob"
                id="dob"
                value={driverForm.dob}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="phone" className={labelStyle}>
                Phone
              </label>
              <PhoneInput
                inputStyle={{
                  display: "block",
                  width: "100%",
                  paddingTop: "24px",
                  paddingBottom: "24px",
                }}
                inputProps={{ id: "phone", name: "phone" }}
                country="pk"
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="password" className={labelStyle}>
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                value={driverForm.password}
                onChange={onChange}
                autoComplete="off"
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="homeTel" className={labelStyle}>
                Home Telephone
              </label>
              <PhoneInput
                inputStyle={{
                  display: "block",
                  width: "100%",
                  paddingTop: "24px",
                  paddingBottom: "24px",
                }}
                inputProps={{ id: "homeTel", name: "homeTel" }}
                country="pk"
                value={homeTel}
                onChange={(homeTel) => setHomeTel(homeTel)}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="insurance" className={labelStyle}>
                National Insurance Number
              </label>
              <input
                type="number"
                name="insurance"
                id="insurance"
                value={driverForm.insurance}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1 sm:col-span-2">
              <label htmlFor="email" className={labelStyle}>
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={driverForm.email}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="sm:sm:col-span-2 flex justify-end">
              <button
                onClick={checkOne}
                className="bg-themeBlue py-2.5 w-40 text-white border border-themeBlue hover:text-themeBlue hover:bg-transparent"
              >
                Next
              </button>
            </div>
          </>
        ) : timeline === "2" ? (
          <>
            <div className="space-y-1 sm:col-span-2">
              <label htmlFor="dbs" className={labelStyle}>
                DBS Number
              </label>
              <input
                type="number"
                name="dbs"
                id="dbs"
                value={driverForm.dbs}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="dbsValidFrom" className={labelStyle}>
                DBS Valid From
              </label>
              <input
                type="date"
                name="dbsValidFrom"
                id="dbsValidFrom"
                value={driverForm.dbsValidFrom}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="dbsValidTo" className={labelStyle}>
                DBS Valid To
              </label>
              <input
                type="date"
                name="dbsValidTo"
                id="dbsValidTo"
                value={driverForm.dbsValidTo}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1 sm:col-span-2">
              <label htmlFor="availibility" className={labelStyle}>
                Availibility Date
              </label>
              <input
                type="date"
                name="availibility"
                id="availibility"
                value={driverForm.availibility}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="idIssue" className={labelStyle}>
                ID Issue Date
              </label>
              <input
                type="date"
                name="idIssue"
                id="idIssue"
                value={driverForm.idIssue}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="idExpiry" className={labelStyle}>
                ID Expiry Date
              </label>
              <input
                type="date"
                name="idExpiry"
                id="idExpiry"
                value={driverForm.idExpiry}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1 sm:col-span-2">
              <label htmlFor="registration" className={labelStyle}>
                Vehicle Registration Number
              </label>
              <input
                type="number"
                name="registration"
                id="registration"
                value={driverForm.registration}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="motIssue" className={labelStyle}>
                MOT Issue Date
              </label>
              <input
                type="date"
                name="motIssue"
                id="motIssue"
                value={driverForm.motIssue}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="motExpiry" className={labelStyle}>
                MOT Expiry Date
              </label>
              <input
                type="date"
                name="motExpiry"
                id="motExpiry"
                value={driverForm.motExpiry}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1 sm:col-span-2">
              <label htmlFor="phv" className={labelStyle}>
                PHV License Number
              </label>
              <input
                type="number"
                name="phv"
                id="phv"
                value={driverForm.phv}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="phvValidFrom" className={labelStyle}>
                PHV License Valid From
              </label>
              <input
                type="date"
                name="phvValidFrom"
                id="phvValidFrom"
                value={driverForm.phvValidFrom}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="phvValidTo" className={labelStyle}>
                PHV License Valid To
              </label>
              <input
                type="date"
                name="phvValidTo"
                id="phvValidTo"
                value={driverForm.phvValidTo}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="sm:sm:col-span-2 flex justify-end gap-x-2">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setTimeline("1");
                }}
                className="bg-themeBlue py-2.5 w-40 text-white border border-themeBlue hover:text-themeBlue hover:bg-transparent"
              >
                Previous
              </button>
              <button
                onClick={checkTwo}
                className="bg-themeBlue py-2.5 w-40 text-white border border-themeBlue hover:text-themeBlue hover:bg-transparent"
              >
                Next
              </button>
            </div>
          </>
        ) : timeline === "3" ? (
          <>
            <div className="space-y-1 sm:col-span-2">
              <label htmlFor="licence" className={labelStyle}>
                License Number
              </label>
              <input
                type="number"
                name="licence"
                id="licence"
                value={driverForm.licence}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1 sm:col-span-2">
              <label htmlFor="vehicle" className={labelStyle}>
                Vehicle Number
              </label>
              <input
                type="number"
                name="vehicle"
                id="vehicle"
                value={driverForm.vehicle}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="drivingValidFrom" className={labelStyle}>
                Driving License Valid From
              </label>
              <input
                type="date"
                name="drivingValidFrom"
                id="drivingValidFrom"
                value={driverForm.drivingValidFrom}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="drivingValidTo" className={labelStyle}>
                Driving License Valid To
              </label>
              <input
                type="date"
                name="drivingValidTo"
                id="drivingValidTo"
                value={driverForm.drivingValidTo}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="vehicleCompany" className={labelStyle}>
                Vehicle Company
              </label>
              <input
                type="text"
                name="vehicleCompany"
                id="vehicleCompany"
                value={driverForm.vehicleCompany}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="vehicleModel" className={labelStyle}>
                Vehicle Model
              </label>
              <input
                type="number"
                name="vehicleModel"
                id="vehicleModel"
                value={driverForm.vehicleModel}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="vehicleYear" className={labelStyle}>
                Vehicle Year
              </label>
              <input
                type="number"
                name="vehicleYear"
                id="vehicleYear"
                value={driverForm.vehicleYear}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="service" className={labelStyle}>
                Service ID
              </label>
              <input
                type="number"
                name="service"
                id="service"
                value={driverForm.service}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1 sm:col-span-2">
              <label htmlFor="address" className={labelStyle}>
                Address
              </label>
              <input
                type="text"
                name="address"
                id="address"
                value={driverForm.address}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1 sm:col-span-2">
              <label htmlFor="postCode" className={labelStyle}>
                Postcode
              </label>
              <input
                type="number"
                name="postCode"
                id="postCode"
                value={driverForm.postCode}
                onChange={onChange}
                className={inputStyle}
              />
            </div>
            <div className="sm:sm:col-span-2 flex justify-end gap-x-2">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setTimeline("2");
                }}
                className="bg-themeBlue py-2.5 w-40 text-white border border-themeBlue hover:text-themeBlue hover:bg-transparent"
              >
                Previous
              </button>
              <button
                onClick={checkThree}
                className="bg-themeBlue py-2.5 w-40 text-white border border-themeBlue hover:text-themeBlue hover:bg-transparent"
              >
                Next
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="space-y-1 sm:col-span-2">
              <label htmlFor="profile" className={labelStyle}>
                Profile Image
              </label>
              <input
                type="file"
                name="profile"
                id="profile"
                onChange={imgHandle}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="vehicleImage" className={labelStyle}>
                Vehicle Image
              </label>
              <input
                type="file"
                name="vehicleImage"
                id="vehicleImage"
                onChange={imgHandle}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="vehicleRegImage" className={labelStyle}>
                Vehicle Registration Image
              </label>
              <input
                type="file"
                name="vehicleRegImage"
                id="vehicleRegImage"
                onChange={imgHandle}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="licenseFrontImage" className={labelStyle}>
                License Front Image
              </label>
              <input
                type="file"
                name="licenseFrontImage"
                id="licenseFrontImage"
                onChange={imgHandle}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="licenseBackImage" className={labelStyle}>
                License Back Image
              </label>
              <input
                type="file"
                name="licenseBackImage"
                id="licenseBackImage"
                onChange={imgHandle}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="phvFrontImage" className={labelStyle}>
                PHV License Front Image
              </label>
              <input
                type="file"
                name="phvFrontImage"
                id="phvFrontImage"
                onChange={imgHandle}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="phvBackImage" className={labelStyle}>
                PHV License Back Image
              </label>
              <input
                type="file"
                name="phvBackImage"
                id="phvBackImage"
                onChange={imgHandle}
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="logbook" className={labelStyle}>
                Logbook Image
              </label>
              <input
                type="file"
                name="logbook"
                id="logbook"
                onChange={imgHandle}
                className={inputStyle}
              />
            </div>
            <div className="sm:sm:col-span-2 flex justify-end gap-x-2">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setTimeline("3");
                }}
                className="bg-themeBlue py-2.5 w-40 text-white border border-themeBlue hover:text-themeBlue hover:bg-transparent"
              >
                Previous
              </button>
              <button
                type="submit"
                onClick={driverFunc}
                className="bg-themeBlue py-2.5 w-40 text-white border border-themeBlue hover:text-themeBlue hover:bg-transparent"
              >
                Sign up
              </button>
            </div>
          </>
        )}
      </form>
    </section>
  );
}
