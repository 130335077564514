import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { info_toaster } from "../toaster/Toaster"

export const getLoginStatus = () => {
    const items = localStorage.getItem("loginStatus");
    return items;
};

export const LoginCheck = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem("loginStatus")) {
            navigate("/sign_in");
            info_toaster("Please Login first!")
        }
    }, [navigate]);
};

export const setLoginStatus = (data) => {
    try {
        localStorage.setItem("loginStatus", data);
    } catch (err) {
        console.log(err);
    }
};