import React from "react";
import { Link } from "react-router-dom";

export default function Section6Card(props) {
  return (
    <div className="rounded-xl overflow-hidden border border-black border-opacity-20">
      <div>
        <img
          src={`/images/sec6-img${props.img}.webp`}
          alt="customer"
          className="w-full"
        />
      </div>
      <div className="px-5 sm:pt-7 pt-4 sm:pb-7 pb-6 flex flex-col justify-center sm:gap-y-4 gap-y-3">
        <h1 className="font-semibold sm:text-3xl text-2xl">{props.title}</h1>
        <p className="font-normal text-sm text-black text-opacity-60">
          {props.para}
        </p>
        <div>
          <Link
            to={props.to}
            className="bg-themeBlue font-normal text-sm text-white py-3 px-4 rounded-md border border-themeBlue hover:bg-transparent hover:text-themeBlue"
          >
            {props.action}
          </Link>
        </div>
      </div>
    </div>
  );
}
