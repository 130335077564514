// @ts-nocheck
import React, { useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  DirectionsRenderer,
} from "@react-google-maps/api";
import DatePicker from "react-date-picker";
import { AiFillCar, AiOutlineCheck } from "react-icons/ai";
import { GoKey } from "react-icons/go";
import TimePicker from "react-time-picker";
import Label from "./booking1Elements/Label";
import TimeLine from "./booking1Elements/TimeLine";
import {
  PlacesAutocomplete,
  PlacesAutocomplete2,
} from "./booking1Elements/MapSearch";
import { PostAPI } from "../../../api/PostAPI";
import {
  error_toaster,
  info_toaster,
  success_toaster,
} from "../../../toaster/Toaster";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { MiniLoader } from "../../../loader/Loader";
import { IMG_URL } from "../../../api/API";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import GetAPI from "../../../api/GetAPI";
import moment from "moment/moment";

export default function Booking1() {
  const [libraries] = useState(["places"]);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCYC3-gTg2XJFIeo9fura6PoNuQzzPeBlc",
    libraries,
  });
  const [selected, setSelected] = useState({ lat: "", lng: "" });
  const [selected2, setSelected2] = useState({ lat: "", lng: "" });
  const [pickAddress, setPickAddress] = useState("");
  const [dropAddress, setDropAddress] = useState("");
  const [directionsResponse, setDirectionsResponse] = useState(null);
  async function calculateRoute() {
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: pickAddress,
      destination: dropAddress,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
  }
  if (pickAddress !== "" && dropAddress !== "") {
    calculateRoute();
  }
  const containerStyle = {
    width: "100%",
    height: "280px",
  };
  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const navigate = useNavigate();
  const [pickUpDropdown, setPickUpDropdown] = useState(false);
  const [dropoffDropdown, setDropoffDropdown] = useState(false);
  const [pickUpPhone, setDropOffPhone] = useState("");
  const [dropOffPhone, setPickUpPhone] = useState("");
  const [inputValue, setInputValue] = useState({
    journey: "",
    hourly: "",
    round: "",
    assistance: "",
    payment: "",
    pickup_next_of_kin_name: "",
    pickup_floor_level: "",
    pickup_parking_availability: "",
    pickup_entry_system: "",
    pickup_entry_instruction: "",
    drop_next_of_kin_name: "",
    drop_floor_level: "",
    drop_parking_availability: "",
    drop_entry_system: "",
    drop_entry_instruction: "",
  });
  const [toggle, setToggle] = useState("ride");
  const [carType, setCarType] = useState("");
  const [vehicle, setVehicle] = useState([]);
  const getVehicle = async () => {
    let vehicle = await PostAPI("available_services", {
      pick_lat: selected.lat,
      pick_long: selected.lng,
      drop_lat: selected2.lat,
      drop_long: selected2.lng,
      hourly: inputValue.hourly,
      round_trip: inputValue.round,
    });
    setVehicle(vehicle?.data?.data);
    if (pickAddress === "") {
      info_toaster("Please Fill PickUp Address");
    } else if (dropAddress === "") {
      info_toaster("Please Fill DropOff Address");
    } else if (inputValue.hourly === "") {
      info_toaster("Please Select Personal Assistance field");
    } else if (inputValue.round === "") {
      info_toaster("Please Select Personal Assistance field");
    } else if (vehicle?.data.status === true) {
      info_toaster("Select your Vehicle!");
    } else {
      setLoader(false);
      error_toaster(vehicle?.data?.message);
    }
  };
  const reGetVehicle = async () => {
    let vehicle = await PostAPI("available_services", {
      pick_lat: selected.lat,
      pick_long: selected.lng,
      drop_lat: selected2.lat,
      drop_long: selected2.lng,
      hourly: inputValue.hourly,
      round_trip: inputValue.round,
    });
    setVehicle(vehicle?.data?.data);
  };
  const bookingFunc = async (e) => {
    e.preventDefault();
    setLoader(true);
    let res = await PostAPI("store_request", {
      pickup_address: pickAddress,
      pickup_lat: selected.lat,
      pickup_long: selected.lng,
      drop_address: dropAddress,
      drop_lat: selected2.lat,
      drop_long: selected2.lng,
      schedule_date: moment(date).format().substring(0, 10),
      schedule_time:
        inputValue.journey === "ASAP"
          ? time.getHours() + ":" + time.getMinutes()
          : time,
      payment_method: "card",
      pm_id: inputValue.payment,
      hourly: inputValue.hourly,
      round_trip: inputValue.round,
      service_id: carType,
      personal_assistance: inputValue.assistance,
      pickup_next_of_kin_name: inputValue.pickup_next_of_kin_name,
      pickup_next_of_kin_tel: pickUpPhone,
      pickup_floor_level: inputValue.pickup_floor_level,
      pickup_parking_availability: inputValue.pickup_parking_availability,
      pickup_entry_system: inputValue.pickup_entry_system,
      pickup_entry_instruction: inputValue.pickup_entry_instruction,
      drop_next_of_kin_name: inputValue.drop_next_of_kin_name,
      drop_next_of_kin_tel: dropOffPhone,
      drop_floor_level: inputValue.drop_floor_level,
      drop_parking_availability: inputValue.drop_parking_availability,
      drop_entry_system: inputValue.drop_entry_system,
      drop_entry_instruction: inputValue.drop_entry_instruction,
    });
    if (pickAddress === "") {
      setLoader(false);
      info_toaster("Please Fill PickUp Address");
    } else if (dropAddress === "") {
      setLoader(false);
      info_toaster("Please Fill DropOff Address");
    } else if (carType === "") {
      setLoader(false);
      info_toaster("Please Select Vehicle");
    } else if (inputValue.payment === "") {
      setLoader(false);
      info_toaster("Please Select Payment Method");
    } else if (inputValue.assistance === "") {
      setLoader(false);
      info_toaster("Please Select Personal Assistance field");
    } else if (res?.data.status === true) {
      setLoader(false);
      success_toaster("Booking Added Successfully!");
      navigate("/", { state: { data: res.data } });
    } else {
      setLoader(false);
      error_toaster(res?.data?.message);
    }
  };
  const cardList = GetAPI("card_list");
  const onChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };
  const onChange2 = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
    reGetVehicle();
  };
  const labelStyle = "font-normal text-sm";
  const inputStyle =
    "bg-white block w-full pl-4 pr-8 py-3 border border-black border-opacity-20 rounded font-normal text-sm focus:outline-none placeholder:text-opacity-40";
  const dropdownStyle =
    "bg-white block w-full pl-4 pr-8 py-[14px] border border-black border-opacity-20 rounded font-normal text-sm cursor-pointer focus:outline-none placeholder:text-opacity-40";
  return (
    <section className="pt-8 pb-16">
      {loader ? (
        <div className="lg:w-4/5 sm:w-10/12 w-11/12 mx-auto bg-themeGray2 rounded-xl h-96 relative">
          <MiniLoader />
        </div>
      ) : (
        <div className="lg:w-4/5 sm:w-10/12 w-11/12 mx-auto bg-themeGray2 rounded-xl grid lg:grid-cols-12">
          <div className="xl:col-span-7 lg:col-span-8 py-6 sm:px-8 px-4 flex flex-col sm:gap-y-7 gap-y-4 lg:order-1 order-2">
            <h1 className="font-semibold sm:text-3xl text-2xl">
              Request a ride
            </h1>
            <div className="grid grid-cols-2">
              <button
                onClick={() => {
                  setToggle("ride");
                }}
                className={`${
                  toggle === "ride"
                    ? "bg-themeBlue text-white border-themeBlue"
                    : "bg-white text-black text-opacity-60 border-black border-opacity-20"
                } w-full py-3 px-5 font-normal text-sm flex justify-center items-center gap-x-3 border rounded-l-md`}
              >
                <AiFillCar size={24} />
                <span>Ride</span>
              </button>
              <button
                onClick={() => {
                  setToggle("rent");
                }}
                className={`${
                  toggle === "rent"
                    ? "bg-themeBlue text-white border-themeBlue"
                    : "bg-white text-black text-opacity-60 border-black border-opacity-20"
                } w-full py-3 px-5 font-normal text-sm flex justify-center items-center gap-x-3 border rounded-r-md`}
              >
                <GoKey size={24} />
                <span>Rent</span>
              </button>
            </div>
            <div className="py-6 px-5 bg-themeSkin2 space-y-7 rounded">
              <div className="grid grid-cols-12 gap-x-3">
                <div className="col-span-1 flex flex-col items-center justify-center pt-4">
                  <div
                    className={`${
                      pickAddress.length > 0 && "bg-themeBlue"
                    } border-4 border-themeBlue rounded-fullest w-4 h-4`}
                  ></div>
                  <img src="/images/booking/line.webp" alt="line" />
                  <div
                    className={`${
                      dropAddress.length > 0 && "bg-themeBlue"
                    } border-4 border-themeBlue w-4 h-4`}
                  ></div>
                </div>
                <div className="col-span-11 space-y-5">
                  <div className="space-y-1">
                    <label htmlFor="pickUp" className={labelStyle}>
                      Pickup location
                    </label>
                    <PlacesAutocomplete
                      setSelected={setSelected}
                      setPickAddress={setPickAddress}
                    />
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="dropOff" className={labelStyle}>
                      Drop-off location
                    </label>
                    <PlacesAutocomplete2
                      setSelected2={setSelected2}
                      setDropAddress={setDropAddress}
                    />
                  </div>
                  <div className="hidden grid-cols-4 gap-2">
                    <input
                      value={pickAddress}
                      onChange={(e) => setPickAddress(e.target.value)}
                      type="text"
                      name="pickUp"
                      id="pickUp"
                      placeholder="Enter location"
                      className={`${inputStyle} col-span-4`}
                      readOnly
                    />
                    <input
                      value={dropAddress}
                      onChange={(e) => setDropAddress(e.target.value)}
                      type="text"
                      name="dropOff"
                      id="dropOff"
                      placeholder="Enter location"
                      className={`${inputStyle} col-span-4`}
                      readOnly
                    />
                    <input
                      value={selected.lat}
                      onChange={(e) => setSelected({ lat: e.target.value })}
                      type="text"
                      name="pickUpLat"
                      id="pickUpLat"
                      className={inputStyle}
                      readOnly
                    />
                    <input
                      value={selected.lng}
                      onChange={(e) => setSelected({ lng: e.target.value })}
                      type="text"
                      name="pickUpLng"
                      id="pickUpLng"
                      className={inputStyle}
                      readOnly
                    />
                    <input
                      value={selected2.lat}
                      onChange={(e) => setSelected2({ lat: e.target.value })}
                      type="text"
                      name="dropOffLat"
                      id="dropOffLat"
                      className={inputStyle}
                      readOnly
                    />
                    <input
                      value={selected2.lng}
                      onChange={(e) => setSelected({ lng: e.target.value })}
                      type="text"
                      name="dropOffLng"
                      id="dropOffLng"
                      className={inputStyle}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-3">
                <div className="grid grid-cols-2 gap-x-3">
                  <div className="space-y-1">
                    <div>
                      <label htmlFor="date" className={labelStyle}>
                        Date
                      </label>
                    </div>
                    <div className="[&>div]:px-3 [&>div]:py-2">
                      <DatePicker
                        onChange={(date) => setDate(date)}
                        value={date}
                        name="date"
                        id="date"
                        className={`h-full w-full bg-white border border-black border-opacity-10 rounded [&>div]:border-none`}
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <div>
                      <label htmlFor="time" className={labelStyle}>
                        Time
                      </label>
                    </div>
                    <div className="[&>div]:px-3 [&>div]:py-2">
                      <TimePicker
                        onChange={(time) => setTime(time)}
                        value={time}
                        name="time"
                        id="time"
                        className={`h-full w-full bg-white border border-black border-opacity-10 rounded [&>div]:border-none`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-6 px-5 bg-themeSkin2 space-y-4 rounded">
              <h4 className="font-medium text-base">Passenger details</h4>
              <div className="grid sm:grid-cols-2 gap-3">
                <div className="space-y-1">
                  <label htmlFor="hourly" className={labelStyle}>
                    Hourly
                  </label>
                  <div className="cursor-pointer">
                    <select
                      onChange={onChange2}
                      value={inputValue.hourly}
                      name="hourly"
                      id="hourly"
                      className={dropdownStyle}
                    >
                      <option value="" className="text-sm">
                        Select
                      </option>
                      <option value="1" className="text-sm">
                        Yes
                      </option>
                      <option value="0" className="text-sm">
                        No
                      </option>
                    </select>
                  </div>
                </div>
                <div className="space-y-1">
                  <label htmlFor="round" className={labelStyle}>
                    Round Trip
                  </label>
                  <div className="cursor-pointer">
                    <select
                      onChange={onChange2}
                      value={inputValue.round}
                      name="round"
                      id="round"
                      className={dropdownStyle}
                    >
                      <option value="" className="text-sm">
                        Select
                      </option>
                      <option value="1" className="text-sm">
                        Yes
                      </option>
                      <option value="0" className="text-sm">
                        No
                      </option>
                    </select>
                  </div>
                </div>
                <div className="space-y-1">
                  <label htmlFor="assistance" className={labelStyle}>
                    Personal Assistance
                  </label>
                  <div className="cursor-pointer">
                    <select
                      onChange={onChange}
                      value={inputValue.assistance}
                      name="assistance"
                      id="assistance"
                      className={dropdownStyle}
                    >
                      <option value="" className="text-sm">
                        Select
                      </option>
                      <option value="1" className="text-sm">
                        Yes
                      </option>
                      <option value="0" className="text-sm">
                        No
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div>
                <button
                  onClick={getVehicle}
                  className="p-3 font-medium text-lg w-full bg-themeBlue text-white rounded border border-themeBlue hover:bg-transparent hover:text-themeBlue"
                >
                  Select Vehicle
                </button>
              </div>
            </div>
            <div className="py-6 px-5 bg-themeSkin2 space-y-4 rounded">
              <h4 className="font-medium text-base">Select Vehicle</h4>
              <div className="space-y-3">
                {vehicle?.map((ele, key) => (
                  <Label
                    inputName="car"
                    onChange={() => {
                      setCarType(ele?.id);
                    }}
                    inputValue={ele?.title}
                    for={ele?.id}
                    passengers={ele?.seats}
                    price={ele?.base_rate}
                    type={ele?.title}
                    text="Everyday choice and comfort - professional driver and medium car for up to 4 people and 2 large bags"
                    img={IMG_URL + ele?.photo}
                  />
                ))}
              </div>
            </div>
            <div className="py-6 px-5 bg-themeSkin2 space-y-4 rounded">
              <div className="flex items-center justify-between">
                <h4 className="font-medium text-base">
                  Extra Pickup Details (Optional)
                </h4>
                <button onClick={() => setPickUpDropdown(!pickUpDropdown)}>
                  {pickUpDropdown ? (
                    <FaChevronUp size={20} />
                  ) : (
                    <FaChevronDown size={20} />
                  )}
                </button>
              </div>
              {pickUpDropdown && (
                <div className="grid sm:grid-cols-2 gap-3">
                  <div className="space-y-1">
                    <label
                      htmlFor="pickup_next_of_kin_name"
                      className={labelStyle}
                    >
                      Next of Kin Name
                    </label>
                    <div className="cursor-pointer">
                      <input
                        type="text"
                        name="pickup_next_of_kin_name"
                        id="pickup_next_of_kin_name"
                        value={inputValue.pickup_next_of_kin_name}
                        onChange={onChange}
                        className={inputStyle}
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <div>
                      <label htmlFor="pickUpPhone" className={labelStyle}>
                        Next of Kin Phone
                      </label>
                    </div>
                    <PhoneInput
                      inputStyle={{
                        display: "block",
                        width: "100%",
                        paddingTop: "22px",
                        paddingBottom: "22px",
                      }}
                      inputProps={{ id: "pickUpPhone", name: "pickUpPhone" }}
                      country="pk"
                      value={pickUpPhone}
                      onChange={(pickUpPhone) =>
                        setPickUpPhone(pickUpPhone.value)
                      }
                    />
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="pickup_floor_level" className={labelStyle}>
                      What floor level of property is the passenger?
                    </label>
                    <div className="cursor-pointer">
                      <input
                        type="number"
                        name="pickup_floor_level"
                        id="pickup_floor_level"
                        value={inputValue.pickup_floor_level}
                        onChange={onChange}
                        className={inputStyle}
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="pickup_parking_availability"
                      className={labelStyle}
                    >
                      Parking Availibility
                    </label>
                    <div className="cursor-pointer">
                      <select
                        onChange={onChange}
                        value={inputValue.pickup_parking_availability}
                        name="pickup_parking_availability"
                        id="pickup_parking_availability"
                        className={dropdownStyle}
                      >
                        <option className="text-sm">Select</option>
                        <option value="1" className="text-sm">
                          Yes
                        </option>
                        <option value="2" className="text-sm">
                          No
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-2 space-y-1">
                    <label htmlFor="pickup_entry_system" className={labelStyle}>
                      Is there a concierge or buzzer or door entry system or key
                      safe?
                    </label>
                    <div className="cursor-pointer">
                      <select
                        onChange={onChange}
                        value={inputValue.pickup_entry_system}
                        name="pickup_entry_system"
                        id="pickup_entry_system"
                        className={dropdownStyle}
                      >
                        <option className="text-sm">Select</option>
                        <option value="1" className="text-sm">
                          Yes
                        </option>
                        <option value="2" className="text-sm">
                          No
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-2 space-y-1">
                    <label
                      htmlFor="pickup_entry_instruction"
                      className={labelStyle}
                    >
                      Entry Instruction
                    </label>
                    <div className="cursor-pointer">
                      <textarea
                        name="pickup_entry_instruction"
                        id="pickup_entry_instruction"
                        value={inputValue.pickup_entry_instruction}
                        onChange={onChange}
                        className={inputStyle}
                      ></textarea>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="py-6 px-5 bg-themeSkin2 space-y-4 rounded">
              <div className="flex items-center justify-between">
                <h4 className="font-medium text-base">
                  Extra Dropoff Details (Optional)
                </h4>
                <button onClick={() => setDropoffDropdown(!dropoffDropdown)}>
                  {dropoffDropdown ? (
                    <FaChevronUp size={20} />
                  ) : (
                    <FaChevronDown size={20} />
                  )}
                </button>
              </div>
              {dropoffDropdown && (
                <div className="grid sm:grid-cols-2 gap-3">
                  <div className="space-y-1">
                    <label
                      htmlFor="drop_next_of_kin_name"
                      className={labelStyle}
                    >
                      Next of Kin Name
                    </label>
                    <div className="cursor-pointer">
                      <input
                        type="text"
                        name="drop_next_of_kin_name"
                        id="drop_next_of_kin_name"
                        value={inputValue.drop_next_of_kin_name}
                        onChange={onChange}
                        className={inputStyle}
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <div>
                      <label htmlFor="dropOffPhone" className={labelStyle}>
                        Next of Kin Phone
                      </label>
                    </div>
                    <PhoneInput
                      inputStyle={{
                        display: "block",
                        width: "100%",
                        paddingTop: "22px",
                        paddingBottom: "22px",
                      }}
                      inputProps={{ id: "dropOffPhone", name: "dropOffPhone" }}
                      country="pk"
                      value={dropOffPhone}
                      onChange={(dropOffPhone) =>
                        setDropOffPhone(dropOffPhone.value)
                      }
                    />
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="drop_floor_level" className={labelStyle}>
                      What floor level of property is the passenger?
                    </label>
                    <div className="cursor-pointer">
                      <input
                        type="number"
                        name="drop_floor_level"
                        id="drop_floor_level"
                        value={inputValue.drop_floor_level}
                        onChange={onChange}
                        className={inputStyle}
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="drop_parking_availability"
                      className={labelStyle}
                    >
                      Parking Availibility
                    </label>
                    <div className="cursor-pointer">
                      <select
                        onChange={onChange}
                        value={inputValue.drop_parking_availability}
                        name="drop_parking_availability"
                        id="drop_parking_availability"
                        className={dropdownStyle}
                      >
                        <option className="text-sm">Select</option>
                        <option value="1" className="text-sm">
                          Yes
                        </option>
                        <option value="2" className="text-sm">
                          No
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-2 space-y-1">
                    <label htmlFor="drop_entry_system" className={labelStyle}>
                      Is there a concierge or buzzer or door entry system or key
                      safe?
                    </label>
                    <div className="cursor-pointer">
                      <select
                        onChange={onChange}
                        value={inputValue.drop_entry_system}
                        name="drop_entry_system"
                        id="drop_entry_system"
                        className={dropdownStyle}
                      >
                        <option className="text-sm">Select</option>
                        <option value="1" className="text-sm">
                          Yes
                        </option>
                        <option value="2" className="text-sm">
                          No
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-2 space-y-1">
                    <label
                      htmlFor="drop_entry_instruction"
                      className={labelStyle}
                    >
                      Entry Instruction
                    </label>
                    <div className="cursor-pointer">
                      <textarea
                        name="drop_entry_instruction"
                        id="drop_entry_instruction"
                        value={inputValue.drop_entry_instruction}
                        onChange={onChange}
                        className={inputStyle}
                      ></textarea>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="py-6 px-5 bg-themeSkin2 space-y-4 rounded">
              <div className="flex justify-between items-center">
                <h4 className="font-medium text-base">Payment details</h4>
                <Link
                  to={"/user_dashboard"}
                  className="py-2 px-4 bg-transparent border border-black border-opacity-40 hover:bg-black hover:text-white"
                >
                  Add Card
                </Link>
              </div>
              <div className="space-y-1">
                <label htmlFor="payment" className={labelStyle}>
                  Choose payment method
                </label>
                <div className="cursor-pointer">
                  <select
                    onChange={onChange}
                    value={inputValue.payment}
                    name="payment"
                    id="payment"
                    className={`${inputStyle} cursor-pointer`}
                  >
                    <option value="" className="text-sm">
                      Select
                    </option>
                    {cardList?.data?.other_cards?.map((card, key) => (
                      <option value={card?.pm_id} className="text-sm">
                        {card?.card_type + card?.last4digit}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  onClick={bookingFunc}
                  className="p-3 font-medium text-lg w-full bg-themeBlue text-white rounded border border-themeBlue hover:bg-transparent hover:text-themeBlue"
                >
                  Book a Ride
                </button>
              </div>
            </div>
          </div>
          <div className="xl:col-span-5 lg:col-span-4 lg:order-2 order-1">
            <div className="xl:w-9/12 lg:w-11/12 lg:ml-auto bg-themeBlue rounded-xl overflow-hidden">
              <div>
                {isLoaded && (
                  <GoogleMap
                    zoom={10}
                    center={
                      pickAddress !== "" && dropAddress !== ""
                        ? selected
                        : { lat: 31.5204, lng: 74.3587 }
                    }
                    mapContainerStyle={containerStyle}
                  >
                    {directionsResponse && (
                      <DirectionsRenderer directions={directionsResponse} />
                    )}
                  </GoogleMap>
                )}
              </div>
              <div className="xl:px-8 px-3 lg:py-10 py-5 grid grid-cols-12">
                <div className="col-span-2 flex flex-col items-center text-white">
                  <div
                    className={`border-2 border-white rounded-fullest w-8 h-8 flex justify-center items-center ${
                      pickAddress.length > 0 && "bg-themeSkin"
                    }`}
                  >
                    {pickAddress.length > 0 && (
                      <AiOutlineCheck size={20} className="text-themeBlue" />
                    )}
                  </div>
                  <img
                    src="/images/booking/white-line.webp"
                    alt="line"
                    className="h-20 w-0.5"
                  />
                  <div
                    className={`border-2 border-white rounded-fullest w-8 h-8 flex justify-center items-center ${
                      dropAddress.length > 0 && "bg-themeSkin"
                    }`}
                  >
                    {dropAddress.length > 0 && (
                      <AiOutlineCheck size={20} className="text-themeBlue" />
                    )}
                  </div>
                  <img
                    src="/images/booking/white-line.webp"
                    alt="line"
                    className="h-20 w-0.5"
                  />
                  <div
                    className={`border-2 border-white rounded-fullest w-8 h-8 flex justify-center items-center ${
                      (inputValue.hourly === "1" || inputValue.hourly === "0") &
                        (inputValue.round === "1" || inputValue.round === "0") &
                        (inputValue.assistance === "1" ||
                          inputValue.assistance === "0") && "bg-themeSkin"
                    }`}
                  >
                    {(inputValue.hourly === "1" || inputValue.hourly === "0") &
                    (inputValue.round === "1" || inputValue.round === "0") &
                    (inputValue.assistance === "1" ||
                      inputValue.assistance === "0") ? (
                      <AiOutlineCheck size={20} className="text-themeBlue" />
                    ) : (
                      ""
                    )}
                  </div>
                  <img
                    src="/images/booking/white-line.webp"
                    alt="line"
                    className="h-20 w-0.5"
                  />
                  <div
                    className={`border-2 border-white rounded-fullest w-8 h-8 flex justify-center items-center ${
                      carType.length !== 0 && "bg-themeSkin"
                    }`}
                  >
                    {carType.length !== 0 && (
                      <AiOutlineCheck size={20} className="text-themeBlue" />
                    )}
                  </div>
                  <img
                    src="/images/booking/white-line.webp"
                    alt="line"
                    className="h-20 w-0.5"
                  />
                  <div
                    className={`border-2 border-white rounded-fullest w-8 h-8 flex justify-center items-center ${
                      inputValue.payment.length > 0 && "bg-themeSkin"
                    }`}
                  >
                    {inputValue.payment.length > 0 && (
                      <AiOutlineCheck size={20} className="text-themeBlue" />
                    )}
                  </div>
                </div>
                <div className="col-span-10 text-white px-2">
                  <TimeLine
                    title="From"
                    text={
                      pickAddress.length > 0
                        ? pickAddress
                        : "Information required"
                    }
                    height={true}
                  />
                  <TimeLine
                    title="To"
                    text={
                      dropAddress.length > 0
                        ? dropAddress
                        : "Information required"
                    }
                    height={true}
                  />
                  <TimeLine
                    title="Passenger Details"
                    text={
                      inputValue.hourly === "1" ||
                      inputValue.hourly === "0" ||
                      inputValue.round === "1" ||
                      inputValue.round === "0" ||
                      inputValue.assistance === "1" ||
                      inputValue.assistance === "0" ? (
                        <>
                          <div className="flex flex-col">
                            {inputValue.hourly === "1" ? (
                              <div>Hourly Based Trip</div>
                            ) : inputValue.hourly === "0" ? (
                              <del>Hourly Based Trip</del>
                            ) : (
                              <></>
                            )}
                            {inputValue.round === "1" ? (
                              <div>Round Trip</div>
                            ) : inputValue.round === "0" ? (
                              <del>Round Trip</del>
                            ) : (
                              <></>
                            )}
                            {inputValue.assistance === "1" ? (
                              <div>Personal Assistance Required</div>
                            ) : inputValue.assistance === "0" ? (
                              <del>Personal Assistance Required</del>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      ) : (
                        "Information required"
                      )
                    }
                    height={true}
                  />
                  <TimeLine
                    title="Vehicle Type"
                    text={carType.length !== 0 ? carType : "Not Selected"}
                    height={true}
                  />
                  <TimeLine
                    title="Payment Details"
                    text={
                      inputValue.payment.length > 0
                        ? inputValue.payment
                        : "Not Selected"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
