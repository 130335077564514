import React from "react";

export default function Mobile(props) {
  return (
    <div className="flex flex-col justify-end items-center sm:gap-y-5 gap-y-3 sm:w-fit">
      <p className="font-normal text-sm text-themeBlue sm:w-4/5 mx-auto">
        {props.text}
      </p>
      <img
        src={`/images/sec5-mobile${props.image}.webp`}
        alt="mobile"
        className="w-60"
      />
    </div>
  );
}
