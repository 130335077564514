import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { Link } from "react-router-dom";

export default function Section3() {
  return (
    <section className="bg-themeBlue2 py-16 relative before:content-[url('../public/images/sec3-circle1.webp')] before:absolute before:right-0 before:top-0 after:content-[url('../public/images/sec3-circle2.webp')] after:absolute after:left-0 after:bottom-0">
      <div className="grid md:grid-cols-2 gap-x-12 gap-y-8 xl:w-3/5 lg:w-4/6 w-4/5 mx-auto">
        <div className="flex justify-center items-center">
          <div>
            <img
              src="/images/sec3-img.webp"
              alt="girl"
              className="md:w-80 w-60"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center md:items-start items-center sm:gap-y-6 gap-y-4">
          <h1 className="font-semibold text-3xl md:text-start text-center">
            Our new arrival
          </h1>
          <p className="font-normal text-base sm:w-4/5 md:text-start text-center">
            We’ve started the journey to a cleaner and more sustainable future
            with the roll-out of new Volkswagen ID.4s into our passenger fleet.
            By 2023, our cars will be entirely electric - two years ahead of
            anyone else.
          </p>
          <div>
            <Link
              to={"/booking"}
              className="bg-themeBlue font-normal text-sm text-white py-2 px-5 rounded-sm border border-themeBlue flex items-center gap-x-2 hover:bg-transparent hover:text-themeBlue"
            >
              Make a booking <FiArrowUpRight size={16} />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
