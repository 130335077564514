import React from "react";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import About1 from "./about1/About1";
import About2 from "./about2/About2";
import About3 from "./about3/About3";
import About4 from "./about4/About4";

export default function About() {
  return (
    <>
      <Header />
      <About1 />
      <About2 />
      <About3 />
      <About4 />
      <Footer />
    </>
  );
}
