import React from "react";
import MiniSection from "./about1Elements/MiniSection";

export default function About1() {
  return (
    <section className="sm:py-16 py-8 relative after:lg:content-[url('../public/images/about/curve.webp')] after:absolute after:bottom-16 after:left-[50%] after:-translate-x-[50%]">
      <div className="lg:w-4/5 w-10/12 mx-auto relative z-10">
        <div className="grid lg:grid-cols-2 gap-x-8 gap-y-12">
          <div className="flex flex-col justify-center sm:gap-y-5 gap-y-2">
            <h6 className="font-normal text-sm text-themeBlue">About us</h6>
            <h1 className="font-semibold sm:text-4xl text-3xl">
              Business class cars at your service
            </h1>
            <p className="font-light text-sm text-black text-opacity-60">
              From humble beginnings to London’s most trusted business-class car
              service in just over forty years, ALLFORCAR have grown with and
              for the times. <br />
              <br /> Presented for your convenience by ALLFORCAR Services Ltd,
              one of London’s most dynamic multi-care services providers. These
              innovative Transport Service Apps are dedicated to disabled-people
              and people with special needs who desire transportation across
              London and Greater London. <br />
              <br /> ALLFORCAR Transport Services also provide Passenger
              Assistants (PA) or Escorts (Care Support Workers), including
              specialized Tour Guides, at an extra-charge, on an hourly or bulk
              fee basis.
            </p>
          </div>
          <div className="flex lg:justify-end justify-center items-center">
            <div>
              <img
                src="/images/about/sec1-img.webp"
                alt="collection"
                className="w-96"
              />
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-3 sm:grid-cols-2 lg:gap-x-20 gap-x-8 gap-y-8 mt-12">
          <MiniSection
            icon="1"
            title="Quality assured"
            para="London’s largest executive fleet of Mercedes cars. With thousands of expertly trained drivers."
          />
          <MiniSection
            icon="2"
            title="Award winning"
            para="We care about our carbon footprint as much as our customer service - just look at these lovely awards"
          />
          <MiniSection
            icon="3"
            title="Heritage and history"
            para="Since 1975 we’ve grown to complete over 10 million journeys a year. Now in 350 cities worldwide"
          />
        </div>
      </div>
    </section>
  );
}
