import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { Link } from "react-router-dom";

export default function Section1() {
  return (
    <section className="bg-themeBlue sm:py-16 py-8">
      <div className="grid md:grid-cols-2 lg:w-4/5 w-10/12 mx-auto">
        <div className="flex flex-col justify-center gap-y-8 xl:w-4/5">
          <h1 className="font-semibold lg:text-5xl md:text-4xl sm:text-5xl text-4xl text-white">
            The fast, affordable way to ride in{" "}
            <span className="text-themePink">London</span>
          </h1>
          <p className="font-light text-base text-white">
            Presented for your convenience by Allforcar Services Ltd, one of
            London’s most dynamic multi-care services providers.
          </p>
          <div className="flex items-center gap-x-3">
            <Link
              to={"/booking"}
              className="bg-white font-normal sm:text-sm text-xs text-themeBlue border border-white py-2 sm:px-4 px-3 rounded uppercase hover:bg-themeBlue hover:text-white"
            >
              get a quote
            </Link>
            <Link
              to={"/driver"}
              className="bg-transparent font-normal sm:text-sm text-xs text-white border border-white py-2 sm:px-4 px-3 rounded uppercase flex items-center gap-x-2 hover:bg-white hover:text-themeBlue"
            >
              <span>become a driver</span>
              <FiArrowUpRight size={16} />
            </Link>
          </div>
        </div>
        <div className="md:flex hidden justify-end items-center">
          <div>
            <img src="/images/sec1-img.webp" alt="circle" className="w-64" />
          </div>
        </div>
      </div>
    </section>
  );
}
