import React from "react";
import { BsCurrencyPound } from "react-icons/bs";

export default function OrderCard(props) {
  return (
    <div className="px-5 py-4 border border-black border-opacity-40 rounded-lg space-y-4">
      <div className="hi\dden text-black">{props.key}</div>
      <div className="flex sm:flex-row flex-col items-center justify-between text-black text-opacity-60">
        <h5 className="font-medium text-base">{props.type}</h5>
        <p className="font-normal text-sm">{props.date}</p>
      </div>
      <div className="grid grid-cols-12">
        <div className="sm:col-span-1 col-span-2">
          <div className="flex flex-col items-center justify-center py-1 gap-y-1">
            <div className="w-6 h-6 border-[6px] border-themeBlue rounded-fullest"></div>
            <img
              src="/images/booking/line.webp"
              alt="line"
              className="w-1.5 h-20"
            />
            <div className="w-6 h-6 border-[6px] border-themeBlue"></div>
          </div>
        </div>
        <div className="sm:col-span-6 col-span-10 flex flex-col justify-between font-normal text-base">
          <p>{props.from}</p>
          <p>{props.to}</p>
        </div>
        <div className="sm:col-span-5 col-span-12 flex justify-end items-start sm:mt-0 mt-2">
          <span
            className={`font-medium text-base ${
              props.status === "Upcoming"
                ? "text-themeBlue"
                : props.status === "Completed"
                ? "text-themeGreen2"
                : props.status === "Cancelled"
                ? "text-themeOrange2"
                : "text-black"
            }`}
          >
            {props.status}
          </span>
        </div>
      </div>
      <div className="flex sm:flex-row flex-col justify-end items-center gap-x-4 gap-y-2">
        {props.status === "Completed" && (
          <p className="font-normal text-base text-themeBlue flex items-center">
            Est fair : <BsCurrencyPound size={18} /> {props.fair}
          </p>
        )}
        {/* <button
          onClick={props.onDetails}
          className="bg-themeBlue3 font-normal text-sm text-black text-opacity-60 sm:w-auto w-full py-2 px-4 rounded border border-themeBlue3 hover:text-opacity-100"
        >
          View Details
        </button> */}
      </div>
    </div>
  );
}
