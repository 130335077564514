import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { FiArrowUpRight } from "react-icons/fi";

export default function Contact1() {
  const labelStyle = "font-normal text-sm";
  const inputStyle =
    "bg-transparent block w-full p-4 rounded-md border border-black border-opacity-20 focus:outline-none font-normal text-sm placeholder:font-light";
  return (
    <section className="sm:pt-12 pt-7 lg:pb-24 sm:pb-16 pb-7 bg-themeBlue">
      <div className="grid lg:grid-cols-2 gap-x-8 gap-y-12 lg:w-4/5 w-10/12 mx-auto">
        <div className="text-white flex flex-col gap-y-6">
          <h1 className="font-semibold text-3xl">Contact our support team</h1>
          <p className="font-light text-sm">
            We’re happy to answer your questions and get you make conversant{" "}
          </p>
          <ul>
            <li className="font-normal text-sm flex items-center gap-x-3">
              <AiOutlineCheck size={16} />
              <span>Learn how to register on Allforcar platform</span>
            </li>
            <li className="font-normal text-sm flex items-center gap-x-3">
              <AiOutlineCheck size={16} />
              <span>Get pricing information</span>
            </li>
            <li className="font-normal text-sm flex items-center gap-x-3">
              <AiOutlineCheck size={16} />
              <span>Submit your complains regarding the issue you face</span>
            </li>
          </ul>
        </div>
        <div className="relative">
          <div className="bg-white rounded-md px-6 py-5 grid sm:grid-cols-2 gap-5 lg:absolute relative w-full shadow-around">
            <div className="space-y-1">
              <label htmlFor="fName" className={labelStyle}>
                First name *
              </label>
              <input
                type="text"
                name="fName"
                id="fName"
                placeholder="First name"
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="lName" className={labelStyle}>
                Last name *
              </label>
              <input
                type="text"
                name="lName"
                id="lName"
                placeholder="Last name"
                className={inputStyle}
              />
            </div>
            <div className="space-y-1 sm:col-span-2">
              <label htmlFor="company" className={labelStyle}>
                Company{" "}
                <span className="text-black text-opacity-60">(Optional)</span>
              </label>
              <input
                type="text"
                name="company"
                id="company"
                placeholder="Company"
                className={inputStyle}
              />
            </div>
            <div className="space-y-1 sm:col-span-2">
              <label htmlFor="email" className={labelStyle}>
                Email *
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email Address"
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="country" className={labelStyle}>
                Country *
              </label>
              <input
                type="text"
                name="country"
                id="country"
                placeholder="Country"
                className={inputStyle}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="zip" className={labelStyle}>
                Zip *
              </label>
              <input
                type="number"
                name="zip"
                id="zip"
                placeholder="Zip code"
                className={inputStyle}
              />
            </div>
            <div className="space-y-1 sm:col-span-2">
              <label htmlFor="zip" className={labelStyle}>
                Message{" "}
                <span className="text-black text-opacity-60">(Optional)</span>
              </label>
              <textarea
                name="zip"
                id="zip"
                placeholder="Zip code"
                className={`${inputStyle} resize-none`}
                rows={5}
              />
            </div>
            <div className="sm:col-span-2">
              <div className="flex justify-end">
                <button className="bg-themeBlue text-white font-normal text-sm py-3 px-5 flex items-center gap-x-3 rounded-md border border-themeBlue hover:bg-transparent hover:text-themeBlue">
                  <span>Send Message</span>
                  <FiArrowUpRight size={16} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
