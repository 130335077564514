import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { ImSwitch } from "react-icons/im";
import { AiOutlineUser } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { info_toaster } from "../../toaster/Toaster";
import { getLoginStatus } from "../../utilities/AuthCheck";
import { MdOutlineDashboard } from "react-icons/md";

export default function Header() {
  const navigate = useNavigate();
  const logoutFunc = (e) => {
    e.preventDefault();
    localStorage.removeItem("loginStatus");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userName");
    navigate("/");
    info_toaster("Logged Out Successfully!");
  };
  const location = useLocation().pathname;
  const [nav, setNav] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const liStyle = "font-light text-base text-white";
  const liStyle2 = "font-light text-base lg:text-black text-white";
  const dropdownItemStyle = "font-light text-sm text-black text-opacity-60";
  return (
    <header
      className={`${location === "/" ? "bg-themeBlue" : "bg-transparent"} py-5`}
    >
      <nav className="lg:w-4/5 w-10/12 mx-auto flex justify-between items-center xl:gap-x-12 gap-x-8">
        <Link to={"/"} className="flex items-center gap-x-2">
          <div>
            <img src="/images/logo.webp" alt="logo" className="max-w-[48px]" />
          </div>
          <span
            className={`font-light text-base ${
              location === "/" ? "text-white" : "text-themeBlue"
            }`}
          >
            Allforcar
          </span>
        </Link>
        <div
          onClick={() => {
            setNav(!nav);
          }}
          className={`w-fit p-2 ${
            location === "/"
              ? "text-white border-white"
              : "text-black border-black"
          } border border-opacity-40 rounded-lg lg:hidden block`}
        >
          <GiHamburgerMenu size={28} />
        </div>
        <div
          className={`${
            nav ? "flex" : "hidden"
          } lg:flex lg:flex-row flex-col lg:items-center xl:justify-between lg:justify-end lg:w-full w-10/12 mx-auto lg:relative absolute lg:top-0 top-24 lg:bg-transparent bg-black lg:bg-opacity-100 bg-opacity-80 lg:px-0 px-5 lg:pt-0 pt-3 lg:pb-0 pb-5 gap-y-4 xl:gap-x-0 lg:gap-x-4 z-50`}
        >
          <ul className="flex lg:flex-row flex-col lg:items-center xl:gap-x-5 lg:gap-x-4 gap-y-2">
            <li className={location === "/" ? liStyle : liStyle2}>
              <button
                onClick={() => {
                  setDropdown(!dropdown);
                }}
                className="flex items-center gap-x-1.5 relative"
              >
                <span>Company</span>
                {dropdown ? <FaChevronUp /> : <FaChevronDown />}
              </button>
              <div
                className={`${
                  dropdown ? "block" : "hidden"
                } lg:absolute relative lg:top-9 bg-white px-3 py-2 rounded lg:w-32 w-[calc(100%-40px)] shadow ${
                  location === "/" ? "shadow-white" : "shadow-black"
                } lg:mr-0`}
              >
                <ul className="space-y-1">
                  <li className={dropdownItemStyle}>
                    <Link to={"/about"} className="block">
                      About
                    </Link>
                  </li>
                  <li className={dropdownItemStyle}>
                    <Link to={"/contact_us"} className="block">
                      Contact us
                    </Link>
                  </li>
                  {/* <li className={dropdownItemStyle}>FAQ's</li>
                  <li className={dropdownItemStyle}>Work with us</li> */}
                </ul>
              </div>
            </li>
            <li className={location === "/" ? liStyle : liStyle2}>
              <Link to={"/services"}>Services</Link>
            </li>
            <li className={location === "/" ? liStyle : liStyle2}>
              <Link to={"/pa_signup"}>Become a PA</Link>
            </li>
          </ul>
          <ul className="flex lg:flex-row flex-col lg:items-center xl:gap-x-5 lg:gap-x-4 gap-y-4">
            {!getLoginStatus() && (
              <li className={location === "/" ? liStyle : liStyle2}>
                <Link to={"/sign_in"}>Sign in</Link>
              </li>
            )}
            <li>
              <Link
                to={"/booking"}
                className={`${
                  location === "/"
                    ? "bg-white text-themeBlue border-white hover:bg-transparent hover:text-white"
                    : "lg:bg-themeBlue bg-white lg:text-white text-themeBlue lg:border-themeBlue border-white lg:hover:bg-transparent lg:hover:text-themeBlue"
                }  font-normal text-xs p-3 uppercase rounded border`}
              >
                Make a booking
              </Link>
            </li>
            {/* <li>
              <Link
                to={"/"}
                className={`${
                  location === "/"
                    ? "bg-transparent text-white border-white hover:bg-white hover:text-themeBlue"
                    : "bg-transparent lg:text-themeBlue text-white lg:border-themeBlue border-white lg:hover:bg-themeBlue lg:hover:text-white"
                } font-normal text-xs px-3 py-2 uppercase rounded border`}
              >
                Become a driver
              </Link>
            </li> */}
            {getLoginStatus() && (
              <>
                <li
                  className={`${
                    location === "/" ? liStyle : liStyle2
                  } relative`}
                >
                  <button
                    onClick={() => {
                      setDropdown2(!dropdown2);
                    }}
                    className="flex items-center gap-x-2 cursor-pointer"
                  >
                    <span
                      className={`${
                        location === "/"
                          ? "lg:bg-black lg:bg-opacity-50"
                          : "lg:bg-themeBlue text-white"
                      } bg-white bg-opacity-60 w-fit p-3 rounded-fullest flex justify-center items-center`}
                    >
                      <AiOutlineUser size={24} />
                    </span>
                    <span>{localStorage.getItem("userName")}</span>
                    {dropdown2 ? <FaChevronUp /> : <FaChevronDown />}
                  </button>
                  <div
                    className={`${
                      dropdown2 ? "block" : "hidden"
                    } lg:absolute relative lg:top-12 top-1.5 lg:left-14 bg-white px-3 py-2 rounded lg:w-32 w-[calc(100%-40px)] shadow ${
                      location === "/" ? "shadow-white" : "shadow-black"
                    } lg:mr-0`}
                  >
                    <ul className="space-y-1">
                      <li className={dropdownItemStyle}>
                        <Link
                          to={"/user_dashboard"}
                          className="flex gap-x-2 items-center"
                        >
                          <MdOutlineDashboard size={20} />
                          <span>Dashboard</span>
                        </Link>
                      </li>
                      <li className={dropdownItemStyle}>
                        <button
                          onClick={logoutFunc}
                          className="flex gap-x-2 items-center"
                        >
                          <ImSwitch size={20} />
                          <span>Logout</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    </header>
  );
}
