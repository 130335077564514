import React from "react";
import { Link } from "react-router-dom";

export default function Section8() {
  return (
    <section className="bg-themeBlue relative before:content-[url('../public/images/sec8-circle2.webp')] before:absolute before:right-0 before:bottom-0 after:content-[url('../public/images/sec8-circle1.webp')] after:absolute after:left-[60%] after:-translate-x-[60%] after:top-0">
      <div className="grid md:grid-cols-2 sm:gap-8 xl:w-3/5 lg:w-4/5 w-10/12 mx-auto">
        <div className="flex flex-col justify-center sm:items-start items-center sm:text-start text-center gap-y-3 sm:py-24 py-16 relative z-10">
          <h1 className="font-semibold sm:text-3xl text-2xl text-white">
            Earn extra money driving
          </h1>
          <p className="font-light sm:text-base text-sm text-white">
            Set your own schedule, be your own boss.
          </p>
          <div>
            <Link
              to={"/driver"}
              className="font-normal sm:text-sm text-xs text-white bg-transparent px-4 py-2 rounded border border-white hover:bg-white hover:text-themeBlue"
            >
              Sign up to drive
            </Link>
          </div>
        </div>
        <div className="flex justify-end items-end">
          <div>
            <img
              src="/images/sec8-car.webp"
              alt="car"
              className="w-80 relative"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
