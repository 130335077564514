import React from "react";
import { HiMinus, HiPlus } from "react-icons/hi";

export default function Accordion(props) {
  return (
    <div className="space-y-3">
      <div className="flex items-start justify-between gap-x-6">
        <h3 className="font-medium text-xl">{props.accordionTitle}</h3>
        <button onClick={props.onOpen}>
          {props.extend ? <HiMinus size={24} /> : <HiPlus size={24} />}
        </button>
      </div>
      {props.extend && (
        <p className="font-light sm:text-base text-sm sm:w-3/5 w-11/12 animate-down">
          {props.accordionText}
        </p>
      )}
    </div>
  );
}
